
.popupMainContainer {
    position: fixed; /* Ensure it stays fixed */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.popupModelContainer {
    width: 760px;
    border: 10px solid rgba(255, 255, 255, 0.4);
    position: absolute;
    display: flex;
    /* left: 25%;
    top: 25%; */
    max-width: 90%;
}

.popupLeftImage{
    /* width: 45%; */
    max-width: 350px;
    /* transform: rotateY(-180deg); */
    background-size: cover; /* Ensures the image covers the entire container */
    background-position: center; /* Centers the image */
    background-repeat: no-repeat; /* Prevents tiling of the image */
}

.popupRightImage{
    /* width: 407px; */
    flex-grow: 1;
    padding: 15px;
    background-image: url(/public/assets/images/home/popupRightImage.png); /* Add your image URL here */
    background-size: cover; /* Ensures the image covers the entire container */
    background-position: center; /* Centers the image */
    background-repeat: no-repeat; /* Prevents tiling of the image */
    display: flex;
    align-items: center;
    justify-content: center;
}


.closeIconMainContainer{
    position: absolute;
    top: 10px;
    right: 15px;
}

.closeIcon{
    width: 18px;
    height: 18px;
    
    border: 2px #00000040;
    cursor: pointer;
    opacity: 0.4;
}

.rightMsgContainer{
    display: flex;
    flex-direction: column;
    gap:2vh;
    font-family: "Times New Roman";
}

.rightMsgFirstSection{
    font-weight: 700;
    font-size: 30px;
    line-height: 1.3;
    text-align: center;
    color: #7FB401;
}

.rightMsgSecondSection{
    
    font-weight: 700;
    font-size: 26px;
    line-height:1.3;
    text-align: center;
    color: #555555;
    font-style: italic;
}

.exploreButtonMainContainer{
    text-align: center;
    padding: 10px;
}
.exploreButton{
    display: inline-block;
    cursor: pointer;
    width: 130px;
    height: 40px;
    border-radius: 3px;
    background-color: #7FB401;
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    color: #FFFFFF;
    border: none;
}

@media(max-width:767px){
    .popupLeftImage{
        max-width: 100%;
    }
    .popupModelContainer{
        flex-direction: column;
        max-width: 360px;
        width: 90%;
        height: auto;
        /* height: 90vh; */
    }
    .rightMsgFirstSection{
        font-size: 25px;
        line-height: 1.2;
    }
    .rightMsgSecondSection{
        font-size: 20px;
    }

    .exploreButton{
        padding: 5px;
        font-size: 20px;
    }
    .popupLeftImage{
        width: 100%;
        height: -webkit-fill-available;
    }
    .popupRightImage{
        padding: 30px 15px;
        margin-top:-70px
    }
    .closeIcon{
        color: white;
        opacity: 1;
    }
    .closeIconMainContainer{
    top: 5px;
    right: 5px;
    background: gray;
    padding: 5px 8px;
    border-radius: 50%;
    }
}

@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  
  .fadeIn {
    animation: fadeIn 0.3s ease-in-out forwards;
  }
  
  .fadeOut {
    animation: fadeOut 0.3s ease-in-out forwards;
  }
  