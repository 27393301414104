.submitButton {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    background: #7fb401;
    color: #FFFFFF;
    border-color: #7fb401;
    padding: 11px 34px;
    margin-top: 15px;
    border: none;
  }

  .submitButton:hover{
    color: #FFFFFF !important
  }

