.submit-button{
    background-color: var(--green);
    color: white;
}
.submit-button:hover{
    background-color: white;
    color:  var(--green) !important;
    border: 1px solid  var(--green) ;
}
.submitButton:hover{
    color: var(--green) !important;
}