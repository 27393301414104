/* .md-custom-p-1 {
    padding: 1rem ;
}
.md-custom-mx-5 {
    margin-left: 6rem ;
    margin-right: 6rem ;
} */

.md-custom-mx-5.md-custom-p-1 {
  padding: 50px 100px 0 100px;
}



@media (max-width: 1200px) {
  .md-custom-mx-5.md-custom-p-1 {
    padding: 50px 50px 0 50px;
  }

  /* .md-custom-p-1 {
        padding: 1rem ;
    }
    .md-custom-mx-5 {
        margin-left: 3rem ;
        margin-right: 3rem ;
    } */
}
/* @media (max-width: 768px) {
    .md-custom-p-1 {
        padding: 1rem ;
    }
    .md-custom-mx-5 {
        margin-left: 2rem ;
        margin-right: 2rem ;
    }
} */

@media (max-width: 576px) {
  .md-custom-mx-5.md-custom-p-1 {
    padding: 20px 20px 0 20px;
  }
}
