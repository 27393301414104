.outerContainer{
    padding: 50px 50px 0 50px;
    text-align: center;
}

.mainHeading {
    font-family: var(--alice);
    font-weight: 00;
    font-size: 29px;
    line-height: 51px;
    margin: auto;
    color: var(--dark);
    position: relative;
    display: inline;
   line-height: 60px;
}

.leaf {
    position: absolute;
    top: 4px;
    right: 27px;
}
.leaf2 {
    position: absolute;
    top: 71px;
    right: 44%;
}
.leaf3 {
    position: absolute;
    top: 71px;
    right: 47%;
}



.image {
    height: 250px;
}

.galleryContent {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 21px;
}

.videoContainer {
    margin-top: 38px;
    margin-bottom: 55px;
    position: relative;
    padding: 50px;
}

.background {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.subHeading {
    font-family: var(--alice);
    font-weight: 00;
    font-size: 29px;
    line-height: 51px;
    margin: auto;
    text-align: center;
    width: 35%;
    
    margin: 15px auto;
    color: var(--black) !important;
}

@media(max-width:1024px) {
    .outerContainer{
        padding: 30px 30px 0 30px;
    }

    .videoContainer {
        padding: 30px;
    }

    .mainHeading {
        font-size: 35px;
    }

    .subHeading {
        width: 80%;
        font-size: 30px !important;
    }

    .image {
        height: 170px;
        border-radius: 15px;
    }

    .image img, video {
        border-radius: 15px;
        height: 100%;
        width: 100%;
    }
    .leaf2{
        position: absolute;
        top: 51px;
        right: 44%;
    }
    
}
@media(max-width:769px) {    
    .leaf2 {
    position: absolute;
    top: 53px;
    right: 42%;
}}
@media(max-width:540px) {
    .outerContainer{
        padding: 20px 20px 0 20px;
    }

    .videoContainer {
        padding: 20px;
    }

    .mainHeading {
        font-size: 25px;
    }

    .subHeading {
        width: 100%;
        font-size: 22px !important;
        line-height: 31px;
    }
    .leaf2 {
        position: absolute;
        top: 34px;
        right: 39%;
    }
}