.container {
  font-family: var(--alice);
  font-style: var(--normal);
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: var(--lightGray);
  padding: 26px 110px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.activeSearchBar {
  background-color: #c6ce3c;
  border-radius: 50%;
}

.activeSearchBar .cartIcon {
    color: #fff;
  }


.green {
  color: var(--green) !important;
  border-color: var(--green) !important;
}

.searchSection {
  width: 15%;
}

.mobileSearchBar {
  /* height: 100%; */
  padding: 20px;
  width: 100%;
  /* background-color: #ccc; */
  background: none;
  position: absolute;
  top: 100%;
  left: 0px;
  background-color: #F4F5F6;

}

.mobileSearchBar span input {
  width: 100%;
}
.mobileSearchBar span {
  width: 100%;
}

.mobileSearchBar,
.mobileViewSearchIcon {
  display: none;
}

.searchContainer {
  position: relative;
  border-radius: 19px;
  border: 1px solid var(--light-1);
}

.search {
  padding: 11px;
  border: 1px solid var(--light-1);
  border-radius: 19px;
  /* width: 100%; */
}
.search2 {
  padding: 11px;
  border: 1px solid var(--light-1);
  border-radius: 19px;
  width: 100%;
  display: none;
}

.search:focus {
  outline-width: 0;
}

.showSearch {
  display: block;
}

.hideSearch {
  display: none;
}

.searchIcon {
  position: absolute;
  right: -120px;
  top: 16px;
  font-size: 14px;
  border-radius: 19px;
}

.logoDiv {
  width: 100%;
  height: 100%;
}

.profileImg {
  width: 42px;
  height: 42px;
  border-radius: 100%;
  border: 1px solid var(--lightGray);
  display: flex;
  align-items: center;
  justify-content: center;
}

.cartImg {
  border: 1px solid var(--green) !important;
}

.mobileSearchImg {
  border: 1px solid var(--green) !important;
}

.signInDetail {
  padding: 0 10px;
}

.cartQuantity {
  color: var(--green);
  font-weight: 600;
  margin-bottom: 0;
}

.signIn {
  font-weight: 600;
  margin-bottom: 0;
}

.profileContainer {
  display: flex;
}

.accountProfile {
  display: flex;
  align-items: center;
}

.cart {
  margin-left: 28px;
}

.mobileViewSearchIcon {
  /* margin-right: 28px; */
  display: none;
}

.cartIcon {
  font-size: 27px;
  color: var(--green);
}

.searchIcon {
  font-size: 27px;
  color: var(--green);
}

.profile {
  font-size: 35px;
  color: var(--lightGray);
}

.burgerMenu {
  display: none;
}

.showMenu {
  display: block;
  font-size: 16px;
}

.showMenu li {
  padding-left: 12px !important;
}

.hideMenu {
  display: none;
}

.menuItem {
  color: var(--lightGray);
  padding-top: 11px;
}

.userPanel {
  list-style: none;
  position: relative;
  top: 5px;
  padding-left: 10px;
  width: 50%;
}
.Logo_searchIcon__kMV8B {
  position: absolute;
  right: -120px;
  top: 16px;
  font-size: 14px;
}

/* Media query */
@media (max-width: 1199px) {
  .container {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    padding: 26px 50px;
    z-index: 99999;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    background-color: var(--white);
  }

  .searchSection {
    width: 22%;
  }

  .profile-img {
    width: 38px;
    height: 38px;
  }

  .logoDiv {
    width: 90%;
  }

  .profileImg {
    width: 38px;
    height: 38px;
  }

  .profile {
    font-size: 31px;
  }

  .cartIcon {
    font-size: 22px;
  }

  .searchIcon {
    font-size: 22px;
  }

  .cart {
    margin-left: 2px;
  }

  .signInDetail {
    display: none;
  }

  .burgerMenu {
    font-size: 33px;
    color: var(--green);
    display: block;
  }
  .sidebarCross {
    color: white;
    margin-left: 90%;
  }
  .cart {
    margin: auto 14px;
  }
  .mobileViewSearchIcon {
    margin-right: 14px;
  }
}

@media (max-width: 769px) {
  .mobileSearchBar,
  .mobileViewSearchIcon {
    display: block;
  }

  .mobileSearchBar{
    padding: 15px;
  }

  .desktopSearchInput {
    display: none !important;
  }

  .container {
    padding: 21px 50px;
  }

  .profile-img img {
    max-width: 84%;
  }

  .logoDiv {
    width: 100%;
  }

  .show {
    display: block;
  }

  .hide {
    display: none;
  }

  .searchSection {
    display: none;
    width: 25%;
  }
}

@media (max-width: 541px) {
  .container {
    padding: 18px 20px;
  }

  .mobileSearchBar{
    padding: 10px;
  }

  .searchSection {
    display: block;
    width: 30%;
  }

  .logoDiv {
    width: 85%;
  }
  .Logo_searchIcon__kMV8B {
    position: absolute;
    right: -120px;
    top: 16px;
    font-size: 14px;
  }
}

@media (max-width: 376px) {
  .topbar {
    font-size: 10px;
  }

  .profileImg {
    width: 33px;
    height: 33px;
  }

  .profile {
    font-size: 27px;
  }

  .cart {
    margin: auto 11px;
  }
  .mobileViewSearchIcon {
    margin-right: 11px;
  }
  .menuItem {
    font-size: 13px;
  }
  /* .Logo_searchIcon__kMV8B {
        position: absolute;
        right: -120px;
        top: 16px;
        font-size: 14px;
    }
*/
}

.menuIcon {
  transform: rotate(180deg);
}

.navbar {
  display: block;
  padding: 10px 50px;
  position: fixed;
  z-index: 99999;
  height: 100%;
  overflow-y: scroll;
  padding: 10px 20px;
  background: #7fb401;
  top: 0;
}

.social {
  display: flex;
  width: 60%;
  justify-content: space-between;
}

.socialLink {
  color: #ffff;
}
