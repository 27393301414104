.paragraph{
    margin-top: 2vh;
    margin-bottom: 2vh;
    font-size: 1.1rem;
    width: 88%;
    height: 24%;
}
.dealers-infoImg{
    margin-top: 0vh !important;
    margin-left: 5.2vw !important;
}

.india-map img{
    width: 100%;
}

.india-map{
    width: 100%;
    margin: 30px auto 0;
    border-radius: 21%;
}

@media (max-width:1440px){
    .dealers-infoImg{
        margin-top: 0vh !important;
        margin-left: 5.5vw !important;
    }
}

@media (max-width:1280px) {
    .dealers-infoImg{
        margin-top: 0vh !important;
        margin-left:6.4vw !important;
    }
    
}

@media (max-width:1024px) {
    .paragraph{
        margin-left: 6.6vw ;
    }
    .dealers-infoImg{
        margin-top: 4vh !important;
        margin-left: 0 !important;
    }
    
}
@media (max-width:768px) {
    .dealers-infoImg{
        margin-top: 6vh !important;
        margin-left: 0 !important;
    }

}
@media (max-width:576px) {
    .paragraph{
        margin-left: 13.6vw;
    }
    .dealers-infoImg{
        margin-top: 0vh !important;
        margin-left: 0.5vw !important;
    }
}

@media (max-width:376px) {
    .dealers-infoImg{
        margin-top: 0vh !important;
        
    }
}    