.topbar {
    display: flex;
    padding: 6px 110px 6px 110px;
    justify-content: space-between;
    font-family: var(--alice);    font-style: var(--normal);
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: var(--lightGray);
    background-color: #F4F5F6;
    align-items: center;
}

.contactContainer {
    display: flex;
}

.contactDetail {
    display: flex;
    align-items: center;
}

.tollFree {
  margin-bottom: 0px; 

}

.tollFree span{
    color: var(--green);
}

.tollFree span:hover{
text-decoration: underline;
cursor: pointer;
}

.mailContainer {
    display: flex;
    align-items: center;
    color: var(--lightGray);
}

.link:hover {
    text-decoration: underline;
}

.accountContainer {
    display: flex;
}

.setting{
    margin: 0px 13.5px;
    color: var(--lightGray);
}

.mail{
    margin-left: 7px;
    margin-bottom: 0px;
    color: var(--green);
}

.account {
    display: flex;
    align-items: center;
    color: var(--lightGray);
    
}
.accountP{
    margin-left: 7px;
    margin-bottom: 0px;

}.saveData{
    margin-left: 7px;
    margin-bottom: 0px;
}

.save {
    display: flex;
    margin-left: 7px;
    align-items: center;
    color: var(--lightGray);

}

.accountIcon, .saveData{
    color: var(--lightGray);
}

.social {
    display: flex;
    /* width: 15.5%; */
    align-items: center;
}

.socialLink {
    width: 27px;
    height: 26px;
    border: 1px solid var(--green);
    color: var(--green);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto 5px;
}

/* Mwdia query */
@media(max-width: 1024px){
    .topbar{
        padding: 6px 50px;
    } 
   .socialLink{
    width: 21px;
    height: 20px;
    margin: auto 3px;
   }
}

@media(max-width: 769px){
    .setting {
        margin: 0px 5.5px;
    }
    .save {
        margin-left: 0px;
    }
}

@media(max-width: 426px){
    .topbar {
        padding: 12px 20px;
        font-size: 11px;
    }
    .accountContainer {
        display: none;
    }   
}

@media(max-width: 376px){
    .topbar {
        font-size: 10px;
    }
}

@media(max-width: 321px){
    .topbar {
        font-size: 8px;
    }
}