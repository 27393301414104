.watsappBtn {
  border-radius: 3px;
  font-size: 20px;
  font-weight: 400;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #7fb401;
  color: #fff;
}

@media screen and (max-width: 400px) {
  .watsappBtn {
    width: 100%;
    font-size: 15px;
  }
}
