.dashboardContainer {
    display: flex;
    background: #FFF;
    padding: 50px 100px 0 0;
    justify-content: space-between;
    width: 100%;
}

.sideBar {
    width: 20%;
    height: auto;
}


.userDataContainer {
    flex: 0 0 auto;
    width: 75%;
    height: auto;
}

.menuList {
    list-style: none;
    width: 100%;
    padding: 0px;
}

.menuItem {
    display: flex;
    align-items: center;
    color: var(--lightGray);
    font-family: var(--alice);    font-size: 22px;
    font-style: var(--normal);
    font-weight: 400;
    line-height: normal;
    padding: 15px 0;
    margin-top: 10px;
    margin-right: 14px;
}

.active {
    color: var(--dark-1);
    border-radius: 0px 33.5px 33.5px 0px;
    background: #E9EFFD;
}

.menuItem p {
    margin-bottom: 0;
}

.icons {
    margin: 0 14px;
    font-size: 25px;
}

@media(max-width: 1440px) {
    .sideBar {
        width: 30%;
    }
}

@media(max-width: 1024px) {
    .dashboardContainer {
        padding: 50px 50px 0 50px;
    }
    .sideBar {
        display: none;
    }

    .userDataContainer {
        width: 100%;
    }
    
}

@media(max-width: 540px) {
    .dashboardContainer {
        padding: 20px 20px 0 20px;
    }
}