.productContainer {
    /* padding: 50px 100px 0 100px; */
}

.mainHeading {
    color: var(--dark);
    font-size: 45px;
    font-family: var(--alice);
    font-style: var(--normal);
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0px;
}

.recomendedProducts {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap:20px;
    margin:10px 0px 
}

.card {
    padding: 10px;
    border: 1px solid black;
}

.image {
    width: 50%;
    margin: auto;
}

.image img {
    width: 100%;
    /* height: 150px; */
}
.details-button{
font-size: 0.5rem !important;
}
.productName {
    color: var(--gray);
    font-size: 0.8rem;
    font-family: var(--alice);    
    font-style: var(--normal);
    font-weight: 600;
    line-height: normal;
    text-align: center;
    margin: 10px 0 0 0;

}

.productPrice {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.productPrice p {
    margin: 0;
}

.icons {
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;
}

.icon {
    border: 1px solid #9C9C9C;
    color: var(--green);
    font-size: 17px;
    padding: 5px;
    width: 15%;
    height: auto;
}

.buttonBorder {
    border: 1px solid black;
    background-color: white;
}


@media(min-width: 1441px) {
    .details-button{
        font-size: 1rem;
        }
}

@media(max-width: 1025px) {
    

    .mainHeading {
        font-size: 37px;
    }

    .image {
        width: 57%;
    }

    .image img {
        height: 77px;
    }

    .productName {
        font-size: 12px;
    }

    .icon {
        width: 22%;
    }
}

@media(max-width: 769px) {
    .image img {
        height: 55px;
    }

    .card {
        padding: 5px;
    }

    .productName {
        font-size: 10px;
    }

    .recomendedProducts {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media(max-width: 541px) {
    

    .mainHeading {
        font-size: 27px;
    }

    .image {
        width: 65%;
    }

    .image img {
        height: 65px;
    }

    .recomendedProducts {
        grid-template-columns: repeat(2, 1fr);
    }

    

    .productName,
    .productPrice,
    .icons {
        margin-top: 9px;
    }
}