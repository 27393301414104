.navbar {
  padding: 0 100px;
  background: #7fb401;
  display: flex;
  justify-content: space-between;
  font-size: 17px;
}



.FreeConsultation {
  margin-top: 14px;
  font-weight: 400;
  margin-right: 0;
}

.FreeConsultation a{
  background-color: white;
}



.UrbanGardening {
  margin-top: 14px;
  font-weight: 400;
  margin-right: 0;
}

.UrbanGardening a {
  background-color: #FFFFFF;
}




.FreeConst:hover {
  color: var(--green);
}
.navList {
  display: flex;
  align-items: center;
  list-style: none;
  margin-bottom: 0;
  justify-content: space-between;
  padding: 0;
}

.farmersMenu {
  position: relative;
}

.FreeConst {
  border: 1px solid var(--white);
    border-radius: 30.5px;
    font-family: 'Alice';
    font-style: normal;
    font-weight: 400;
    text-wrap: nowrap;
    line-height: 135.52%;
    letter-spacing: 0.01em;
    color: var(--gray);
    padding: 5px 17px;
    transition: all 0.3s ease-in-out;
}

.farmersLink {
  position: absolute;
  top: 45px;
  z-index: 1;
  list-style: none;
  left: -3px;
  background-color: #7fb401;
  border-radius: 5px;
  min-width: 13rem;
  /* box-shadow: 0 2px 40px 8px rgba(15, 15, 15, 0.15); */
  border-radius: 4px;
  padding: 10px 0;
}

.subMenu {
  padding-left: 2%;
}

.subMenu:hover {
  color: #7fb401;
  background-color: #fff;
}

.showMenu {
  display: block;
}

.hideMenu {
  display: none;
}

.menuIcon {
  transform: rotate(180deg);
}

.farmerItem {
  font-family: var(--alice);
  color: var(--gray);
  line-height: 156%;
  font-size: 16px;
  padding: 4px 24px;
  width: 100%;
  padding: 10px;
}

.farmerItem:hover {
  /* color: var( --green-3); */
  background-color: var(--green-5);
  color: var(--white);
  width: 100%;
}

.navLine {
  margin: 0;
}

.navItem {
  font-family: var(--alice);
  font-style: var(--normal);
  font-weight: 400;
  line-height: 24px;
  color: var(--white);
  padding: 14px 5px;
  margin-right: 20px;
  text-wrap: nowrap;
}

.navItem a {
  color: #fff;
}

.hideNavbar {
  display: block !important;
}

.showNavbar {
  display: none !important;
}

.searchContainer {
  display: none;
}

.showSubMenu {
  display: block;
}

.hideSubMenu {
  display: none;
}

.desktopNavbar {
  display: block;
}

.social{
  display: flex;
  gap: 10px;
}

/* media query */
@media (max-width: 1441px) {
  .navItem {
    margin-right: 10px !important;
  }
  .navbar{
    padding: 0 15px;
  }
}

/* media query */
@media (max-width: 1250px) {
  .navbar{
    font-size: 15px;
  }
}



@media (max-width: 1199px) {
  .navList {
    display: block;
  }
  .desktopNavbar {
    display: none;
  }


  .navItem {
    font-size: 16px;
  }

  .hide {
    display: none;
  }

  .show {
    display: block;
  }

  .navbar {
    width: 70%;
    display: block;
    padding: 10px 50px;
    position: absolute;
    z-index: 9;
    /* height: 100%; */
  }
}

@media (max-width: 1024px) {

  .navItem {
    padding: 12px 0;
    font-size: 17px;
  }

  .search {
    padding: 9px 11px;
    border: 1px solid var(--light-1);
    border-radius: 21px;
    width: 100%;
    margin-bottom: 10px;
  }

  .search:focus {
    outline-width: 0;
  }

  .searchIcon {
    position: absolute;
    right: 13px;
    top: 28px;
    font-size: 18px;
  }

  .searchContainer {
    display: none;
    padding: 14px 0;
    position: relative;
    width: 60%;
  }
}

@media (max-width: 769px) {
  .searchContainer {
    display: block;
    padding: 14px 0;
    position: relative;
    width: 60%;
  }
}

@media (max-width: 541px) {
  .navbar {
    padding: 10px 20px;
    width: 90%;
  }
}

@media (max-width: 376px) {
  .navItem {
    padding: 9px 0;
    font-size: 14px;
  }

  .searchContainer {
    padding: 9px 0;
    width: 100%;
  }

  .searchIcon {
    top: 23px;
  }

  .farmerItem {
    font-size: 14px;
  }
}

.subMenu {
  color: white;
}