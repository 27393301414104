.footerBar {
    padding: 30px 110px;
    background:#223D17;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: var(--alice);    font-style: var(--normal);
}

.minfert {
    text-align: center;
    color: var(--white);
    font-weight: 500;
    font-size: 15px;
    line-height: 141.4%;
    margin: 0;
    margin-right: 5px;
}
.minfert:hover {
    color: var(--white);
}

.menus {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    font-weight: 400;
    font-size: 15px;
    line-height: 141.4%;
    width: 25%;
    margin: 0;
}

.navItems{
    color: var(--white);   
}

.dot{
    width: 5px;
    height: 5px;
    background-color: var(--white);
    border-radius: 100%;
}

@media(max-width: 1025px){
    .footerBar{
        padding: 20px 50px; 
    }
    .menus{
        width: 42%;
    }
}

@media(max-width: 769px){
    .menus{
        width: 53%;
    }
}
@media(max-width: 865px){
    .footerBar{
        flex-direction: column;
    } 
}

@media(max-width: 541px){
    .footerBar{
        padding: 20px; 
    } 
    .menus{
        width: 100%;
        padding: 0;
    }
    
}