.contact-us .first-section {
    display: flex;
    width: 100%;
    padding: 0 110px;
}

.contact-us .second_section {
    display: flex;
    justify-content: space-between;
}

.contact-section .first_section {
    display: flex;
    justify-content: space-between;
}

.contact-us .second_section .second-1 {
    min-height: 48px;
}

.desktop_heading {
    display: block;
}

.mobile_heading {
    display: none;
}

.contact-us .first-section .column-1 {
    width: 48%;
    margin-top: 73px;
}

.contact-us .first-section .column-1 h1 {
    display: inline;
}

.contact-us .first-section .column-1 h1,
.contact-us .first-section .column-2 h1 {
    font-family: "Alice";
    font-style: normal;
    font-weight: 400;
    font-size: 29px;
    line-height: 51px;
    color: #323232;
    /* display: inline; */
}

.contact-us .first-section .column-1 h2 {
    margin-top: 50px;
    font-family: "Alice";
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 51px;
    color: #323232;
}

.contact-us .first-section .column-1 h2 {
    font-family: "Alice";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 166.5%;
    color: #4b4b4b;
}

.contact-us .first-section .column-1 p {
    font-family: "Alice";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 166.5%;
    color: #4b4b4b;
    margin: 0;
}

.contact-us .first-section .column-1 .contact-social {
    display: flex;
    align-items: center;
}

.contact-us .first-section .column-1 .contact-social .social-button {
    width: 40px;
    height: 40px;
    border: 0.5px solid #7fb401;
    border-radius: 100%;
    margin-right: 9px;
    margin-top: 27px;
    justify-content: center;
    align-items: center;
    display: flex;
}


/* .contact-us .first-section .column-1 .contact-section {
  display: flex;
  justify-content: space-between;
} */

.contact-us .first-section .column-2 {
    /* height: 720px; */
    width: 52%;
    margin-top: 30px;
    margin-left: 78px;
}

.contact-us .first-section .contact-us-form {
    border: 1px solid #9c9c9c;
    border-radius: 29px;
    padding: 30px;
    margin-top: 20px;
}

.contact-us .column-2 h2 {
    font-family: "Alice";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 166.5%;
    color: #4b4b4b;
    /* margin-top: 50px; */
}

.contact-us .column-2 p {
    font-family: "Alice";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 166.5%;
    color: #4b4b4b;
}

.contact-us .column-2 .contact-form-inputs label {
    /* margin-top: 25px; */
    font-size: 13px;
    display: block;
}
 .placeholderText {
    font-size: 15px;
}
.contact-us .column-2 .contact-form-inputs input,
.contact-us .column-2 .contact-form-inputs select,
.contact-us .column-2 .contact-form-inputs textarea {
    display: block;
    width: 100%;
    height: 50px !important;
}


/* .contact-us .column-2 .contact-form-inputs textarea {
  width: 100%;
  height: 221px;
} */

.contact-us .column-2 .contact-us-form button {
    font-family: "Alice";
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 150%;
    letter-spacing: -0.002em;
    margin-top: 34px;
    height: 60px;
    border: none;
    color: #ffff;
    width: 100%;
    background: #7fb401;
    border-radius: 2px;
}

.contact-form-inputs {
    margin-left: 1px;
}

.contact-form-inputs input {
    height: 50px;
}

.contact-us-heading {
    position: relative;
    display: inline;
}

.contact-us-heading .leaf {
    position: absolute;
    top: -15px;
    right: 60px;
}

.leaf2 {
    position: absolute;
    top: 6px;
    right: 160px;
}

@media(max-width: 1355px) {
    .contact-us .first-section .column-2 {
        margin-left: 35px;
    }
    .contact-us .first-section .column-1 .contact-social .social-button {
        margin-top: 0;
    }
}

@media (max-width: 1024px) {
    .contact-us {
        width: 100%;
    }
    .contact-us .first-section {
        width: 100%;
        padding: 0 50px;
    }
    .contact-us .first-section .column-1 p {
        font-size: 12.2px;
    }
    .contact-us .first-section .column-1 h2 {
        margin-top: 30px;
        font-size: 18px;
    }
    .contact-us .first-section .column-2 {
        margin-left: 30px;
    }
    .contact-us .first-section .column-1 h1 {
        font-size: 35px;
    }
}

@media (max-width: 768px) {
    .contact-us-heading {
        position: relative;
        display: inline;
    }
    .contact-us-heading .leaf {
        position: absolute;
        top: -15px;
        right: 60px;
    }
    .contact-us .first-section .column-1 {
        margin-left: 30px;
        padding-right: 30px;
        width: 100%;
    }
    .contact-us .first-section {
        /* display: block; */
        flex-direction: column-reverse;
        align-items: center;
    }
    .contact-us .first-section .column-2 {
        width: 95%;
        margin-left: 0px;
    }
    .leaf2 {
        position: absolute;
        top: 6px;
        right: 378px;
    }
}

@media (width: 768px) {
    .custom-md-visible {
        display: inline !important;
    }
    .custom-md-hidden {
        display: none !important;
    }
}

@media (max-width: 525px) {
    .contact-section {
        margin-top: -30%;
    }
    .desktop_heading {
        display: none;
    }
    .mobile_heading {
        display: block;
    }
    .contact-us .first-section {
        padding: 0 15px;
    }
    .contact-us .first-section .column-1 {
        margin-left: 0;
        padding-right: 0;
        width: 100%;
    }
    .contact-us .first-section .column-2 {
        height: 680px;
        margin-left: 0;
        width: 100%;
    }
    .contact-us .column-2 h2 {
        margin-top: 0px;
    }
    .contact-us .first-section .contact-us-form {
        padding: 26px;
    }
    .contact-us .column-2 .contact-us-form button {
        font-size: 19px;
        margin-top: 29px;
        height: 43px;
    }
    .contact-us .column-2 .contact-form-inputs input,
    .contact-us .column-2 .contact-form-inputs select,
    .contact-us .column-2 .contact-form-inputs textarea {
        height: 43px!important;
    }
    .contact-us .column-2 .contact-form-inputs label {
        margin-top: 8px;
    }
    .contact-us .first-section .column-1 p {
        font-size: 13px;
    }
    .contact-us .first-section .column-1 h2 {
        font-size: 19px;
        margin-top: 15px;
    }
    .contact-us .first-section .column-1 {
        margin-top: 40px;
    }
    .contact-us .first-section .column-1 h1,
    .contact-us .first-section .column-2 h1 {
        font-size: 30px;
    }
    .contact-section {
        display: block !important;
    }
    .first_section div,
    .second_section div {
        width: 50%;
    }
}