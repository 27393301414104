.submit-button{
    background-color: var(--green);
    color: white;
}
.submit-button:hover{
    background-color: white;
    color:  var(--green);
    border: 1px solid  var(--green) ;
}

    .authLeft{
        align-items: center;
        background: white !important;
        background-image: url(http://localhost:3000/static/media/bg-pattan.edd31f7….png) !important;
        display: flex;
        border: 1px solid #ddd;
        height: auto !important;
        padding: 20px 20px;
        /* width: 100% !important; */
        width: 40vw;
    }