/* Main container */
.whatsAppMainContainer {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 101;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Smooth fade animation */
.show {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
}

.hide {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
}

/* Chat box */
.chatWithUs {
  width: 110px;
    height: 30px;
    border-radius: 30px;
    background: linear-gradient(to bottom, #FF8000, #FF4D00);
    font-size: 14px;
    font-family: Arial;
    display: flex
;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    position: fixed;
    bottom: 4.5rem;
    right: 1rem;
    z-index: 101;
}

/* Down arrow */
.downArrow {
  position: fixed;
  bottom: 3.7rem;
  right: 1.9rem;
  z-index: 101;
}

/* Close icon */
.closeIcon {
  position: fixed;
  bottom: 6.3rem;
  right: 0.5rem;
  z-index: 101;
  background-color: #FFFFFFCC;
  height: 15px;
  width: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7.5px;
  cursor: pointer;
}

/* WhatsApp floating icon (Always Visible) */
.float {
  position: fixed;
  bottom: 0rem;
  right: 0rem;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
}

.prevent-color-change {
  width: 75px;
  border: none;
}
