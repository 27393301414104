.userProfileContainer{
 margin-left: 30px;
}

.headingContainer {
    display: flex;
    align-items: center;
    margin-bottom: 23px;
}

.userProfile {
    width: 9%;
}

.userProfile img {
    width: 100%;
}

.content {
    color: var(--gray);
    font-family: var(--alice);    font-style: var(--normal);
}

.heading {
    font-size: 25px;
    font-weight: 600;
    line-height: normal;
}

.content p {
    font-size: 15px;
    font-weight: 400;
    line-height: 166.5%;
    margin-left: 10px;
}

.form {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
    justify-content: space-between;
}

.inputField {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-top: 20px;
}

.inputField label {
    color: var(--gray);
    font-family: var(--alice);    font-size: 25px;
    font-style: var(--normal);
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0;
}

.inputField input {
    background: var(--light-3);
    padding: 20px 15px;
    border: none;
    margin: 10px 0;
    color: var(--lightGray);
    font-family: var(--alice);    font-size: 20px;
    font-style: var(--normal);
    font-weight: 400;
    line-height: normal;
}

.password {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.forgot {
    color: var(--green);
    font-family: var(--alice);    font-size: 20px;
    font-style: var(--normal);
    font-weight: 500;
    line-height: normal;
    margin: 0;
    border: none;
    background-color: var(--white);
}

.update {
    background-color: var(--green);
    color: var(--white);
    font-family: var(--alice);    font-size: 20px;
    font-style: var(--normal);
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.04px;
    border: none;
    padding: 5px 20px;
    margin-right: 5%;
}

.updateContainer {
    display: flex;
    justify-content: end;
    width: 100%;
    margin-top: 20px;
}

.updateContainer p {
    color: var(--lightGray);
    font-family: var(--alice);    font-size: 15px;
    font-style: var(--normal);
    font-weight: 400;
    line-height: normal;
    margin: 0 10px;
}

.text-green {
    color: var(--green);
}