.templateContainer {
    padding: 20px;
}

.aboutForm {
    padding: 20px;
    border-radius: 10px;
    background-color: whitesmoke;
}

.mainHeading {
    font-size: 25px;
    font-weight: 600;
    margin: 15px 0px;
}

.subHeading{
    font-size: 20px;
    font-weight: 600;
    margin-top: 20px;
}

textarea{
    height: 35px!important;
}

.mainFormContainer {
    background-color: var(--white);
    border: 1px solid var(--gray);
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 10px;
}

.sectionContainer{
    margin: 20px 0;
}

.fieldName{
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}
.formField{
    height: 36px !important;
    /* resize: none; */
}
.red{
    color: red;
}
.submitButton{
    margin-left: 10px;
    background-color: var(--green);
    color: var(--white);
    font-size: 16px;
    font-weight: 500;
    padding: 5px 9px;
}

.closeButton{
    margin-left: 10px;
    background-color: var(--gray);
    color: var(--white) important!;
    font-size: 16px;
    font-weight: 500;
    padding: 5px 9px;
}

.addDataButton{
    display: flex;
    align-items: end;
}

.addDataButton_home{
    display: flex;
    height: fit-content;
    margin-top: 40px;
}


.addDataButton_home button{
    background-color: var(--green);
    color: var(--white);
    border: none;
    padding: 4px 12px;
    margin-left: 10px;
    margin-bottom: 11px;
    font-size: 20px;
}

.addDataButton_home button:focus{
    border: none;
    border-width: 0px;
    border-color: var(--green);
}

.addDataButton button{
    background-color: var(--green);
    color: var(--white);
    border: none;
    padding: 4px 12px;
    margin-left: 10px;
    margin-bottom: 11px;
    font-size: 20px;
}

.addDataButton button:focus{
    border: none;
    border-width: 0px;
    border-color: var(--green);
}

.mainImage{
    margin-top: 4px;
    width: 100px;
    height: 100px;
}

.btn-link {
	color:white;
}
