.loader-container {
  /* display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;

  background-color: #fbfdfa;
  overflow: hidden; */

      /* display: flex
; */
    /* justify-content: center; */
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: #fbfdfa;
    z-index: 99999;
    top: 0;
    left: 0;
}

.loader-gif {
  width: 100%;
  height: 100%;
  object-fit: contain;
  overflow: hidden;
}
