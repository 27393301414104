.body {
  overflow-x: hidden;
}
.checkout {
  padding: 50px 100px 0 100px;
}

.addressSection,
.name,
.stateDetails,
.contactDetails,
.detailsSection {
  display: flex;
  justify-content: space-between;
  /* width: 345px; */
  margin-left: auto;
}

.width {
  width: 48%;
}

.payment_methods_sections {
  width: 345px;
  margin-left: auto;
}

.couponsection {
  display: flex;
  /* margin-left: 78%; */
  justify-content: end;
  margin-top: 13px;
  padding-bottom: 4px;
}

.addcoupon {
  height: 47px;
  width: 100%;
}

.couponbutton {
  min-width: 90px;
  color: white;
  height: 47px;
  background-color: #7fb401;
  border: none;
}

.couponListing {
  /* display: ; */
  width: 345px;
  margin-left: auto;
}

.coupon_list {
  margin: 20px 0;
  padding-bottom: 20px;
  border-bottom: 1px solid #575656;
  display: flex;
  align-items: center;
}

.coupon_list button {
  background: #7fb401;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 500;
  height: 30px;
  transition: all 0.3s ease;
  margin-right: 10px;
}

.coupon_list_p {
  margin-bottom: 10px;
  font-size: 15px;
  color: #575656;
  font-weight: 500;
}

.remove_coupon {
  width: 345px;
  margin-left: auto;
  text-decoration: underline;
  cursor: pointer;
}

.inputSection {
  width: 345px;
  display: flex;
  justify-content: end;
  align-items: flex-end;
}

.input_div {
  width: 100%;
}

.validationerror {
  margin-top: 51px;
  position: absolute;
  color: red;
}

.validationerror1 {
  margin-top: 51px;
  position: absolute;
  color: red;
  margin-left: 260px;
}

.checkbox {
  margin: 0 !important;
  line-height: 0 !important;
  margin-right: 10px !important;
}


.billingSection {
  width: 50%;
  margin-right: 57px;
}

.checkout .addressSection .shippingSection {
  width: 50%;
}

.billingSection h2,
.shippingSection h2 {
  font-family: var(--alice);
  font-style: var(--normal);
  font-weight: 400;
  font-size: 45px;
  line-height: 51px;
  color: var(--dark);
  position: relative;
  max-width: fit-content;
}

.authLeft {
  align-items: center;
  background: white !important;
  background-image: url(http://localhost:3000/static/media/bg-pattan.edd31f7….png) !important;
  display: flex;
  border: 1px solid #ddd;
  height: auto !important;
  padding: 20px 20px;
  /* width: 100% !important; */
  width: 40vw;
}

.submit-button {
  background-color: var(--green);
  color: white;
}
.submit-button:hover {
  background-color: white;
  color: var(--green);
  border: 1px solid var(--green);
}

.billingSection h2 {
  margin-bottom: 15px;
}

.shippingSection h2 {
  margin-bottom: 47px;
}

.billingSection h2 img {
  position: absolute;
  top: 5px;
  right: 38px;
}

.shippingSection h2 img {
  position: absolute;
  top: 5px;
  right: 35px;
}

/* .sameAsBill {
  display: flex;
  align-items: center;
  font-family: var(--alice);
  font-style: var(--normal);
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: var(--green);
}

.sameAsBill p {
  margin: 0;
} */

.container {
  display: block;
  position: relative;
  padding-left: 23px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 13px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 12px;
  width: 12px;
  /* background-color: #eee; */
  outline: 1.5px solid var(--gray-1);
  border-radius: 2px;
}

/* On mouse-over, add a grey background color */


/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: var(--green);
  outline: 1.5px solid var(--green);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 4px;
  top: 0px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.billingSection input,
.shippingSection input {
  background: var(--light-2);
  margin-bottom: 30px;
  height: 50px;
  font-family: var(--alice);
  font-style: var(--normal);
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  border: none;
  color: var(--lightGray);
  padding-left: 15px;
}

.countryWidth {
  width: 100%;
}

.note {
  width: 100%;
  background: var(--light-2);
  margin-bottom: 30px;
  height: 168px;
  font-family: var(--alice);
  font-style: var(--normal);
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  border: none;
  color: var(--lightGray);
  padding-left: 15px;
  padding-top: 15px;
}

.orderDetails h2 {
  margin-top: 51px;
  margin-bottom: 44px;
  font-family: var(--alice);
  font-style: var(--normal);
  font-weight: 400;
  font-size: 30px;
  line-height: 51px;
  color: var(--dark);
}

.detailsSection .orderDetail p {
  margin-top: 37px;
  width: 60%;
  word-wrap: normal;
  font-family: var(--alice);
  font-style: var(--normal);
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  letter-spacing: -0.022em;
  color: var(--gray);
  word-wrap: normal;
}

.checkoutProceed p {
  margin-top: 18px;
  text-align: center;
  width: 100%;
  font-family: "Kohinoor Devanagari";
  font-style: var(--normal);
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: -0.022em;
  color: var(--gray-1);
}

.detailsSection button {
  margin-top: 37px;
  width: 345px;
  height: 47px;
  font-style: var(--normla);
  font-weight: 600;
  font-size: 15px;
  line-height: 180.02%;
  color: var(--white);
  background: #7fb401;
  border: none;
}

.orderDetails thead tr {
  border-bottom: 1px solid var(--dark);
  font-family: var(--alice);
  font-style: var(--normal);
  font-weight: 700;
  font-size: 25px;
  line-height: 50px;
  color: var(--gray);
}

.orderDetails thead tr th:last-child,
.orderDetails tbody tr td:last-child {
  text-align: right;
  padding-right: 42px;
}

.orderDetails tbody tr {
  margin-top: 17px;
  font-family: var(--alice);
  font-style: var(--normla);
  font-weight: 500;
  font-size: 22px;
  line-height: 40px;
  color: var(--gray-1);
}

.orderDetails tbody tr td {
  padding-top: 17px;
}

.productDetails {
  border-top: 1px solid var(--dark);
  border-bottom: 1px solid var(--dark);
  border-collapse: collapse;
  width: 100%;
}

.priceValue {
  display: inline-block;
  text-align: left;
}

td img {
  padding-right: 10px;
}

.secureIcon {
  font-size: 27px;
}
.addressForm {
  width: 50vw;
}

.checkoutContainer{
  display:flex; 
  flex-direction:column;
  gap:20px
}

.addressContainer{
  display:grid; 
  grid-template-columns: 1fr 1fr; 
  gap:20px
}

.addressLabel{
  display:flex;
  gap:10px; 
  align-items:start
}

.addressContent{
  font-size:13px; 
  color:var(--gray-1)
}

.newAddress{
  border-top:1px solid black; 
  border-bottom:1px solid black; 
  padding:10px 0px
}

.addressToggle{
  display:flex;
   justify-content: space-between; 
   align-items:center;
  cursor:pointer
}
.orderHistory{ 
  border-bottom:1px solid black; 
}
.orderHistoryToggle{
  display:flex;
  justify-content:space-between;
  align-items:center; 
  cursor:pointer;
  padding:10px 0px
}




.addressLabel input[type='radio']:checked:after {
  width: 18px;
  height: 18px;
  border-radius: 15px;
  top: -3px;
  left: -3px;
  position: relative;
  background-color: #7fb401;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}


@media (max-width: 1024px) {
  .checkout {
    padding: 50px 50px 0 50px;
  }

  .billingSection h2,
  .shippingSection h2,
  .orderDetails h2 {
    font-size: 37px;
  }

  .billingSection {
    margin-right: 37px;
  }

  .detailsSection button {
    font-size: 20px;
    width: 272px;
    height: 50px;
  }

  .orderDetail p {
    width: 76%;
  }

  .orderDetails thead tr {
    font-size: 23px;
  }

  .orderDetails tbody tr {
    font-size: 20px;
  }

  .billingSection h2 img {
    right: 32px;
  }

  .shippingSection h2 img {
    right: 27px;
  }
}

@media (max-width: 768px) {
  .addressForm {
    width: 90vw;
  }

  .checkout .addressSection,
  .checkout .detailsSection {
    display: block;
  }

  .billingSection h2 {
    margin-bottom: 20px;
  }

  .billingSection,
  .shippingSection {
    width: 100% !important;
  }

  .shippingSection {
    margin-top: 20px;
  }

  .company {
    margin-bottom: 30px;
  }

  .couponsection {
    display: flex;
    margin-left: 0%;
    margin-top: 13px;
    padding-bottom: 4px;
    font-size: 15px;
  }

  .addcoupon {
    height: 47px;
    width: 236px;
  }

  .couponbutton {
    min-width: 90px;
    color: white;
    height: 47px;
    background-color: #7fb401;
  }

  .billingSection input,
  .shippingSection input {
    padding-left: 15px;
    font-size: 15px;
  }

  .orderDetail p {
    width: 100%;
  }

  .detailsSection button {
    width: 100%;
  }

  .orderDetails h2 {
    margin-top: 0;
    margin-bottom: 10px;
  }
}

@media (max-width: 540px) {
  .checkout {
    padding: 20px 20px 0 20px;
  }

  .billingSection h2,
  .shippingSection h2,
  .orderDetails h2 {
    font-size: 27px;
  }

  .orderDetails thead tr {
    font-size: 20px;
  }

  .orderDetails tbody tr {
    font-size: 18px;
  }

  .orderDetail p {
    width: 100% !important;
  }

  .detailsSection button {
    font-size: 20px;
    height: 50px;
    width: 100%;
  }

  .orderDetails thead tr th:last-child,
  .orderDetails tbody tr td:last-child {
    padding-right: 0px;
  }

  .checkbox {
    width: auto !important;
  }

  .addressSection,
  .name,
  .stateDetails,
  .contactDetails,
  .detailsSection {
    display: block;
  }

  .billingSection input,
  .shippingSection input,
  .company {
    margin-bottom: 15px;
    width: 100%;
  }

  .width {
    width: 100%;
  }

  .shippingSection h2 {
    margin-bottom: 0px;
  }

  .checkout .addressSection .billingSection h2 {
    margin-bottom: 18px;
  }
}

/* addresses css */

.addresses {
  margin: 20px 0;
}

.addresses h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 156.52%;
  letter-spacing: 0.005em;
  margin-bottom: 23px;
}

.address_section {
  display: grid;
  /* grid-template-columns: repeat(auto-fill, minmax(32%, 1fr)); */
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  font-size: 10px;
}

.address_section .shipping-card {
  width: 100%;
  margin-left: auto;
  height: auto;
  border: 1px solid #d9d9d9;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.card_1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.address_section .new_address_icon {
  color: #575656;
  font-size: 10px;
  font-weight: 600;
  line-height: 156.523%;
  letter-spacing: 0.075px;
}

.add_address {
  text-align: center;
}

.address_section .deliver_div {
  text-align: end;
}

.address_section .deliver_btn {
  background: transparent;
  color: #7fb401;
  font-size: 10px;
  font-weight: 600;
  padding: 4px 6px;
}

.card_2 h4 {
  font-size: 20px;
  font-family: "Alice";
  font-weight: 600;
  line-height: 156.523%;
  letter-spacing: 0.1px;
  font-size: 15px;
}

.selectAddress {
  font-size: 20px;
  font-family: "Alice";
  font-weight: 600;
  line-height: 156.523%;
  letter-spacing: 0.1px;
  font-size: 15px;
}
.address_section .phone_number {
  color: #575656;
  font-size: 10px;
  line-height: 156.523%;
  letter-spacing: 0.075px;
}

.address_section .edit_button {
  color: #575656;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.075px;
  border: none;
  background-color: white;
}

.address_section .deliver_btn1 {
  color: #7fb401 !important;
  border: 1px solid #7fb401 !important;
  background: none !important;
}

.setBackground {
  font-size: 15px;
  font-weight: 600;
  padding: 2px 11px;
  width: 195px;
}

.setBackground2 {
  background: #7fb401 !important;
  color: #fff !important;
}

@media (max-width: 820px) {
  .address_section {
    grid-template-columns: 1fr 1fr 1fr !important;
    grid-gap: 20px;
  }
}
@media (max-width: 426px) {
  .address_section {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr)) !important;
    grid-gap: 20px;
  }

  .address_section .shipping-card {
    height: auto;
  }

  .address_section .deliver_btn {
    font-size: 13px;
  }

  .card_2 h4 {
    font-size: 18px;
  }

  .address_section .order_address {
    margin: 6px 0;
  }

  .addresses h2 {
    margin-bottom: 18px;
  }
}

@media (max-width: 321px) {
  .address_section .order_address {
    font-size: 14px;
  }

  .detailsSection button {
    width: 81%;
  }
  .addcoupon {
    height: 47px;
    width: 192px;
  }

  .couponListing {
    /* display: ; */
    width: 279px;
    margin-left: auto;
  }
}
