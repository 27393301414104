.productListContainer {
  padding: 0px 100px;
}

.productList {
  padding: 20px 50px;
  margin: 50px 0;
  display: flex;
  justify-content: space-around;
  /* border: 1px solid black; */
  box-shadow: 0px 0px 10px 0px #2b481d;
}
.leaf {
  position: absolute;
  top: 292px;
  right: 671px
}

.productImg {
  width: 22%;
  display: flex;
  align-items: center;
}

.productImg img {
  width: 100%;
  height: auto;
}

.productDetail {
  width: 51.9%;
}

.productDetail {
  color: var(--gray);
  font-family: var(--alice);
  font-style: var(--normal);
}

.productName {
  color: var(--gray);
  font-size: 22px;
  font-weight: 400;
  line-height: normal;
  font-family: var(--alice);
  margin-bottom: 0;
}

.productDetail p {
  font-size: 1.1rem;
  font-weight: 400;
  font-family: var(--alice);
  margin: 15px 0;
  /* min-height: 135px; */
}

.buyNow {
  background: var(--green);
  color: var(--white) !important;
  font-size: 15px;
  font-weight: 600;
  line-height: 180.023%;
  padding: 8px 23px;
  border: none;
  width: 16.6%;
}

.desktopLinks {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2%;
}

.desktopLogoImage1 {
  width: 80px;
}

.desktopLogoImage2 {
  width: 150px;
  position: relative;
  bottom: 5px;
  left: 5px;
}

.buttons {
  width: 15.9%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.buttons button {
  background: var(--green);
  color: var(--white);
  font-size: 12px;
  font-family: var(--alice);
  font-style: var(--normal);
  font-weight: 600;
  line-height: 180.023%;
  width: 39%;
  height: 160%;
  border: none;
  text-align: center;
}

.productVarient {
  display: flex;
  justify-content: space-between;
  padding-bottom: 3vh;
}

.cart {
  font-size: 22px;
}

.save {
  font-size: 19px;
}

.productPrice {
  display: flex;
  align-items: center;
  color: var(--gray);
  font-family: var(--alice);
  font-style: var(--normal);
  font-size: 25px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 2vh;
}

.productPrice p {
  margin-bottom: 0;
}

.productPriceMobile {
  display: none;
}

.mobileBtn {
  display: none;
}
.secondHeading {
  font-family: var(--alice);
  font-style: var(--normal);
  font-weight: 400;
  line-height: 51px;
  margin-bottom: 13px;
  color: var(--gray);
  margin: 3%;
  margin-left: 8%;
  font-size: 29px;
}
.imgDiv {
  display: flex;
  padding-left: 8vw;
  padding-right: 8vw;
  justify-content: space-around;
}
.categoryImg {
  justify-content: space-around;
  height: 350px;
  border-radius: 21px;
}
.categoryContent {
  margin-left: 4vw;
  font-family: var(--alice);
  font-size: 1.1rem;
}

/* Media query */
@media (min-width:1101px) and (max-width:1301px) {
  .categoryContent {
    font-size: 0.9rem;
  }
  .leaf {
    position: absolute;
    top: 285px;
    right: 567px;
  
  }
}
@media (max-width: 1025px) {
  .productListContainer {
    padding: 0 50px;
  }
  .imgDiv {
    padding-left: 5.5vw;
  }
  .categoryImg {
    height: 310px;
  }
  .categoryContent {
    font-size: 0.8rem;
  }
  .productList {
    padding: 20px;
  }

  .productDetail p {
    margin: 10px 0;
    font-size: 15px;
  }

  .productImg {
    width: 16%;
  }

  .buttons {
    width: 24%;
  }

  .buttons button {
    width: 40%;
    height: 46px;
    font-size: 15px;
  }

  .buyNow {
    font-size: 15px;
  }

  .cart {
    font-size: 28px;
  }

  .save {
    font-size: 20px;
  }
  .leaf {
    position: absolute;
    top: 215px;
    right: 465px; 
  
  }
  .categoryContent {
    font-size: 1.1rem;
    text-align: justify;
  }
  
}

@media (max-width: 769px) {
  .productList {
    flex-direction: column;
  }
  .imgDiv {
    padding-left: 7vw;
  }
  
  .categoryImg {
    height: 230px;
  }

  .showMoreBtn {
    width: 100px;
  }

  .knowMoreBtn {
    width: 100px;
  }

  .productImg {
    width: 25%;
    margin: auto;
  }

  .productDetail {
    width: 100%;
    margin: 20px 0;
  }

  .buttons {
    flex-direction: row;
    width: 100%;
  }

  .buttons button {
    width: 87px;
    height: 43px;
    margin-right: 10px;
  }

  .productPrice {
    align-items: center;
  }

  .mobileBtn {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .desktopLogoImage1 {
    position: relative;
    left: 310px;
    color: black;
    width: 40px;
  }

  .desktopLogoImage2 {
    position: relative;
    left: 320px;
    color: yellow;
    width: 40px;
  }

  .desktopBtn {
    display: none;
  }

  .productPriceMobile {
    display: flex;
    align-items: center;
    color: var(--gray);
    font-family: var(--alice);    font-style: var(--normal);
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
  }

  .productPriceMobile p {
    margin: 0;
    font-size: 20px !important;
  }

  .productPrice {
    display: none;
  }

  .buyNow {
    min-width: 118px;
    padding: 6px 23px;
  }
  .buttons button:last-child {
    margin-right: 0px;
  }
  .leaf {
    position: absolute;
    top: 202px;
    right: 343px;
}
  }


@media (max-width: 541px) {
  .productListContainer {
    padding: 0 20px;
  }

      .productDetail p {
    padding-right: 8%;
  }
  .imgDiv {
    padding-left: 5.5vw;
    padding-right: 5.5vw;
    display: block;
  }
  .categoryImg {
    width: 100%;
  }

  .categoryContent {
    margin-left: 0;
    margin-top: 4%;
  }

  .mobileBtn {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .desktopLogoImage1 {
    position: relative;
    left: 30px;
    width: 40px;
  }

  .desktopLogoImage2 {
    position: relative;
    left: 40px;
    width: 40px;
  }

  .productList {
    padding: 10px;
    margin: 20px 0;
  }

  .productImg {
    width: 30%;
  }

  .buttons button {
    width: 55px;
    height: 39px;
  }
  .leaf {
    position: absolute;
    top: 185px;
    right: 179px;
}
  
  }


@media (max-width: 376px) {
  .buttons button {
    width: 45px;
    height: 39px;
  }

  .shopNowBtn {
    width: 80px;
  }

  .knowMoreBtn {
    width: 80px;
  }

  .desktopLogoImage1 {
    position: relative;
    left: 5px;
    width: 30px;
  }

  .desktopLogoImage2 {
    position: relative;
    left: 10px;
    width: 30px;
  }
  .leaf {
    position: absolute;
    top: 183px;
    right: 155px;
  
  }


}
@media (max-width:321px) {
  .leaf {
    position: absolute;
    top: 178px;
    right: 131px;

}
  
  
  
}