.productListContainer {
  padding: 0px 100px;
}

.productList {
  padding: 20px 50px;
  margin: 50px 0;
  display: flex;
  justify-content: space-around;
  /* border: 1px solid black; */
  box-shadow: 0px 0px 10px 0px #2b481d;
}

.productImg {
  width: 22%;
  display: flex;
  align-items: center;
}

.productImg img {
  width: 100%;
  height: auto;
}

.leaf {
  position: absolute;
  top: 273px;
  right: 670px;
}
.productDetail {
  width: 51.9%;
}

.productDetail {
  color: var(--gray);
  font-family: var(--alice);
  font-style: var(--normal);
}

.productName {
  color: var(--gray);
  font-size: 22px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}

.productDetail p {
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 180.023%;
  margin: 15px 0;
  /* min-height: 135px; */
}

.buyNow {
  background: var(--green);
  color: var(--white) !important;
  font-size: 15px;
  font-weight: 600;
  line-height: 180.023%;
  padding: 8px 23px;
  border: none;
  width: 16.6%;
}

.buttons {
  width: 15.9%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.buttons button {
  background: var(--green);
  color: var(--white);
  font-size: 15px;
  font-family: var(--alice);
  font-style: var(--normal);
  font-weight: 600;
  line-height: 180.023%;
  width: 39%;
  height: 160%;
  border: none;
  text-align: center;
}

.productVarient {
  display: flex;
  justify-content: space-between;
  padding-bottom: 3vh;
}

.cart {
  font-size: 29px;
}

.save {
  font-size: 19px;
}

.productPrice {
  display: flex;
  align-items: center;
  color: var(--gray);
  font-family: var(--alice);
  font-style: var(--normal);
  font-size: 25px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 2vh;
}

.productPrice p {
  margin-bottom: 0;
}

.productPriceMobile {
  display: none;
}

.mobileBtn {
  display: none;
}

.secondHeading {
  font-family: var(--alice);
  font-style: var(--normal);
  font-weight: 400;
  line-height: 51px;
  margin-bottom: 13px;
  color: var(--gray);
  margin: 3%;
  margin-left: 8%;
  font-size: 29px;
}

.imgDiv {
  display: flex;
  padding-left: 8vw;
  padding-right: 8vw;
  justify-content: space-around;
}

.categoryImg {
  justify-content: space-around;
  height: 350px;
  border-radius: 21px;
}

.categoryContent {
  margin-left: 4vw;
  font-family: var(--alice);
  font-size: 1.1rem;
}

.showMoreBtn {
  width: 100px;
}

.knowMoreBtn {
  width: 100px;
}

.desktopLinks {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.desktopLogoImage1 {
  width: 80px;
}

.desktopLogoImage2 {
  width: 150px;
  position: relative;
  bottom: 5px;
  left: 5px;
}

/* New Page CSS */
.urbanNewMainContainer {
  font-family: "Times New Roman";
}

.urbanHeadingMainContainer {
  margin-bottom: 15px;
  text-align: center;
}
.urbanNewHeading {
  font-weight: 700;
  font-size: 50px;
  color: #7fb401;
  margin-bottom: 0px;
}
.urbanFirstSection {
  width: 100%;
  height: 100%;
  margin-bottom: 0px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 57.2px;
  color: #ffffff;
}

.urbanBannerImage {
  width: 100%;
  max-height: 450px;
  object-fit: cover;
  object-position: center;
}

.overlapContainer {
  position: absolute; /* Ensure it stays fixed */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.3); /* Dimmed background */
}

.urbanBannerContents {
  position: absolute;
  z-index: 10;
  line-height: 1.2;
}

.treeLeafImage {
  width: 49px;
  height: 34.32px;
  background-size: cover; /* Ensures the image covers the entire section */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  background-position: center; /* Centers the image */
  position: relative;
  left: 145px;
  top: 20px;
}

.urbanBannerHeading1 {
  font-weight: 700;
  text-align: center;
  font-size: 44px;
}
.urbanBannerHeading2 {
  font-weight: 400;
  text-align: center;
  font-size: 44px;
}

.ellipseMainContainer {
  position: relative;
}

.secondSectionEllipse {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  bottom: -1px;
  z-index: 10;
}

.secondSectionCards {
  position: relative;
    z-index: 99;
    width: 100%;
    padding: 0px 185px 54px;
    bottom: 65px;
}
.whyChooseSection {
  border-radius: 10px;
  border: 1px solid #dbf2d6;
  background-color: #f3fbf2;
  padding: 25px 85px;
}

.whyChooseSubSection {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.whyChooseSubSectionLeftContent {
  font-size: 30px;
  font-weight: 700;
}

.whyChooseSubSectionRightContent {
  width: 60%;
  /* height: 138px; */
  color: #ffffff;
  display: flex;
  justify-content: space-between;
}

.logoImg {
  width: 86px;
  height: 86px;
}

.logoSectionContent {
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #444444;
}

.rightLogoContentAlign {
  width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  justify-content: flex-start;
  gap: 10px;
}

.secondSectionDescription {
  font-size: 18px;
  color: #121212;
  line-height: 28.8px;
  font-weight: 400;
  margin-top: 54px;
}

.exclusiveProductsSection {
  position: relative;
  z-index: 10;
  bottom: 60px;
  background: linear-gradient(to bottom, #faf8eb, #faf8eb00);
}

.exclusiveProductHeading {
  font-weight: 700;
    font-size: 30px;
    color: #333333;
    text-align: center;
    padding: 60px 0px 50px;
    line-height: 40px;
    margin-bottom: 0px;
}

.exclusiveProductsWrapper {
  /* padding: 0px 100px; */
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}

.exclusiveProductCard {
  padding: 11px;
    background-color: #f3fbf2;
    border-radius: 10px;
    position: relative;
    border: 1px solid #dbf2d6;
    overflow: hidden;
    transition: box-shadow 0.3s ease;
    /* flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(33.33% - 60px);
    max-width: calc(33.33% - 40px); */
    flex: 0 0 calc(33.33% - 27px);
}

.exclusiveProductCard:hover {
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.1); /* Deeper shadow on hover */
}

.exclusiveProductCard::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%; /* Start at the center */
  width: 0;
  height: 3px;
  background-color: #7fb401;
  border-bottom-left-radius: 10px; /* Match the card's border radius */
  border-bottom-right-radius: 10px; /* Match the card's border radius */
  transition: width 0.4s ease, left 0.4s ease; /* Smooth animation */
}

.exclusiveProductCard:hover::after {
  width: 100%; /* Expand to full width */
  left: 0; /* Align to the left edge */
}

.exclusiveProductImage {
  width: 100%;
  border-radius: 10px;
}

.productDetailsMainContent {
  margin: 15px 10px 15px;
}
.productDetailSubContent{
  padding-bottom: 10px;
}
.productDetailsContent {
  display: flex;
  justify-content: space-between;
}

.productName {
  font-weight: 700;
    font-size: 24px;
    color: #333333;
    line-height: 30px;
}

.productCategory {
  font-weight: 400;
  font-size: 18px;
  color: #666666;
  line-height: 25px;
}

.buttonsContainer {
  display: flex;
  gap: 13px; /* Adds the gap between buttons */
  /* justify-content: flex-start; */
}

.cardActiveButton,
.cardInactiveButton {
  flex: 1 1;
    border-radius: 3px;
    font-size: 17px;
    font-weight: 400;
    padding: 7px 0px;
    text-align: center;
    cursor: pointer;
    max-height: 40px;

}

.cardActiveButton {
  background-color: #7fb401;
  color: #ffffff;
  border: 1px solid #7fb401;
}

.cardInactiveButton {
  background-color: transparent;
  color: #7fb401;
  border: 1px solid #7fb401;
}

.cardActiveButton:hover {
  color: #ffffff;
}

.cardInactiveButton:hover {
  color: #7fb401;
}

@media(max-width: 1350px){
  .secondSectionCards {
      padding: 0px 100px 54px;
  }
  .whyChooseSection {
    padding: 25px 55px;
  }
}

@media (min-width: 1101px) and (max-width: 1301px) {

  .categoryContent {

    font-size: 0.9rem;

  }

  .leaf {

    position: absolute;

    top: 267px;

    right: 567px;

  }
}

@media (max-width: 1199px) {

 .productListContainer {

    padding: 0 50px;

  }

  .rightLogoContentAlign{
    width: 22%;
  }

  

  .imgDiv {

    padding-left: 5.5vw;

  }

  .categoryImg {

    height: 310px;

  }

  .categoryContent {

    font-size: 1.1rem;

  }

  .productList {

    padding: 20px;

  }

  .productDetail p {

    margin: 10px 0;

    font-size: 13px;

  }

  .productImg {

    width: 16%;

  }

  .buttons {

    width: 24%;

  }

  .buttons button {

    width: 40%;

    height: 46px;

    font-size: 15px;

  }

  .buyNow {

    font-size: 15px;

  }

  .cart {

    font-size: 28px;

  }

  .save {

    font-size: 20px;

  }

  .leaf {

    position: absolute;

    top: 197px;

    right: 465px;

  }


  .secondSectionCards {
    padding: 0px 100px 54px;
  }
  
  .whyChooseSection {
    padding: 20px;
  }
  .whyChooseSubSection {
    flex-direction: column;
    gap: 20px;
  }
  .whyChooseSubSectionRightContent {
    width: 100%;
  }
  /* .exclusiveProductCard {
    flex: 0 0 calc(50% - 30px);
  } */
  .productName{
    font-size: 20px;
  }
  .productCategory{
    font-size: 15px;
  }
}

@media(max-width:1023px){
  .secondSectionCards{
    padding: 0px 50px 54px; 
  }
  .exclusiveProductsWrapper{
    padding: 0px 50px;
    display: flex
;
    flex-wrap: wrap;
    gap: 35px;
  }


  .exclusiveProductCard {
    flex: 0 0 calc(50% - 18px);
  }
}

@media (max-width: 820px) {
  .productList {

    flex-direction: column;

  }

  .imgDiv {

    padding-left: 7vw;

  }

  .categoryContent {

    font-size: 0.6rem;
  }

  .categoryImg {

    height: 230px;

  }

  .productImg {

    width: 25%;

    margin: auto;

  }

  .productDetail {

    width: 100%;

    margin: 20px 0;

  }
  .buttons {

    flex-direction: row;

    width: 100%;

  }

  .buttons button {

    width: 87px;

    height: 43px;

    margin-right: 10px;

  }

  .productPrice {

    align-items: center;

  }

  .mobileBtn {

    display: flex;

    justify-content: space-between;

    margin: 10px 0;

  }

  .desktopBtn {

    display: none;

  }

  .productPriceMobile {

    display: flex;

    align-items: center;

    color: var(--gray);

    font-family: var(--alice);    font-style: var(--normal);

    font-size: 20px;

    font-weight: 600;

    line-height: normal;

  }

  .productPriceMobile p {

    margin: 0;

    font-size: 20px !important;
  }

  .productPrice {

    display: none;

  }

  .buyNow {

    min-width: 118px;

    padding: 6px 23px;

  }

  .buttons button:last-child {

    margin-right: 0px;

  }

  .showMoreBtn {

    width: 100px;

  }



  .knowMoreBtn {

    width: 100px;

  }



  .mobileBtn {

    display: flex;

    justify-content: flex-start;

    align-items: center;

  }



  .desktopLogoImage1 {

    position: relative;

    left: 310px;

    color: black;

    width: 40px;

  }



  .desktopLogoImage2 {

    position: relative;

    left: 320px;

    color: yellow;

    width: 40px;

  }

  .leaf {

    position: absolute;

    top: 186px;

    right: 344px;

  }
}

@media(max-width:767px){
  .whyChooseSubSectionRightContent{
    flex-wrap: wrap;
  }
  .rightLogoContentAlign{
    width: 48%;
    margin-bottom: 15px;
  }

  .secondSectionCards {
    padding: 0px 45px 54px;
  }
  .exclusiveProductsWrapper{
    /* padding: 0px 45px; */
    gap: 25px;
  }

  .exclusiveProductCard {
    flex: 0 0 calc(50% - 13px);
  }
}


@media (max-width: 576px) {
  .exclusiveProductCard {
    flex: 0 0 100%;
  }

  .exclusiveProductHeading{
    /* padding: 20px 0px; */
    padding: 40px 0px 30px;
    font-size: 25px;
  }

  .productListContainer {

    padding: 0 20px;

  }

  .imgDiv {

    padding-left: 5.5vw;

    padding-right: 5.5vw;

    display: block;

  }

  .categoryImg {

    width: 100%;

  }

  .categoryContent {

    margin-left: 0;

    margin-top: 4%;

  }

  .productList {

    padding: 10px;

    margin: 20px 0;

  }

  .productImg {

    width: 30%;

  }

  .buttons button {

    width: 55px;

    height: 39px;

  }

  .productDetail p {

    min-height: 0px;

    width: 335px;
  }

  .mobileBtn {

    display: flex;

    justify-content: flex-start;

    align-items: center;

  }



  .desktopLogoImage1 {

    position: relative;

    left: 30px;



    width: 40px;

  }



  .desktopLogoImage2 {

    position: relative;

    left: 40px;



    width: 40px;

  }

  .leaf {

    position: absolute;

    top: 166px;

    right: 180px;

  }

  .secondSectionCards {
    padding: 0px 10px 54px;
    bottom: 55px;
  }
  .secondSectionDescription{
    font-size: 15px;
  }
  .whyChooseSection {
    padding: 15px;
  }

  .secondSectionCards{
    padding: 0px 30px 54px; 
  }
  .exclusiveProductsWrapper{
    padding: 0px 30px;
  }
  .urbanNewHeading {
    font-size: 35px;
  }
  
  .treeLeafImage {
    width: 25px;
    height: 25px;
    left: 103px;
    top: 15px;
  }

  .urbanBannerHeading1 {
    font-size: 20px;
    margin-bottom: 0px;
  }
  .urbanBannerHeading2 {
    font-size: 20px;
  }

  .whyChooseSubSectionLeftContent {
    font-size: 20px;
  }

  .logoImg {
    width: 70px;
    height: 70px;
  }
}

@media (max-width: 426px) {
  .cardActiveButton,
  .cardInactiveButton {
    font-size: 15px;
  }

  .secondSectionCards {
    bottom: 40px;
  }

  .secondSectionDescription{
    margin-top: 35px;
  }

  .logoSectionContent{
    font-size: 15px;
  }
}
@media (max-width: 380px) {

  .buttons button {

    width: 45px;

    height: 39px;

  }

  .shopNowBtn {

    width: 80px;

  }



  .productDetail p {

    width: 260px;
  }



  .knowMoreBtn {

    width: 80px;

  }



  .desktopLogoImage1 {

    position: relative;

    left: 5px;

    width: 30px;

  }



  .desktopLogoImage2 {

    position: relative;

    left: 10px;

    width: 30px;

  }

  .leaf {

    position: absolute;

    top: 166px;

    right: 166px;

  }

  .urbanBannerHeading1 {
    font-size: 25px;
  }
  .urbanBannerHeading2 {
    font-size: 25px;
  }

  /* .productName {
    font-size: 14px;
  } */
  /* .productCategory {
    font-size: 12px;
  } */
  
}
@media (max-width: 325px) {

  .leaf {

    position: absolute;

    top: 159px;

    right: 132px;

  }

  .productDetail p {

    width: 240px;

  }
  .urbanBannerHeading1 {
    font-size: 20px;
  }
  .urbanBannerHeading2 {
    font-size: 20px;
  }

  .productName {
    font-size: 16px;
  }
  .productCategory {
    font-size: 14px;
  }
}
