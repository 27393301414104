.plantgrowthContainer {
  /* padding: 0 110px; */
  margin-top: 150px;
}

.mainContainer {
  position: relative;
  font-style: var(--normal);
}

.mainHeadingContainer {
  width: 50%;
  margin: auto;
  text-align: center;
}

.leaf {
  position: absolute;
  top: -2px;
  right: 47%;
}

.link {
  text-decoration: underline;
  color: var(--gray) !important;
}

.link:hover {
  color: var(--dark-1);
  text-decoration: underline;
}

.mainHeading {
  position: relative;
  font-family: var(--alice);
  font-weight: 00;
  font-size: 29px;
  /* line-height: 51px; */
  text-align: center;
  color: var(--dark);
  display: inline;
}

.mainContent {
  font-family: var(--alice);
  font-weight: 400;
  font-size: 13px;
  line-height: 166.5%;
  text-align: center;
  color: var(--gray);
  margin: 15px auto;
}

.boxContainer {
  display: grid;
  grid-template-columns: auto auto auto;
  width: 100%;
  margin: 70px 0;
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  font-style: var(--normal);
}

.boxHeading {
  font-family: var(--alice);
  font-weight: 400;
  font-size: 22px;
  line-height: 29px;
  color: var(--dark);
  text-align: center;
}

.boxImg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.boxContent {
  font-family: var(--alice);
  font-weight: 400;
  font-size: 13px;
  /* line-height: 26px; */
  text-align: center;
  letter-spacing: 0.01em;
  color: var(--gray);
  width: 80%;
}

.boxPaddingFirstAndLastRow {
  padding: 20px;
}

.boxPaddingCenterRow {
  padding: 20px;
}

/* Media Query */
@media (min-width: 1175px) and (max-width: 1285px) {
  .plantgrowthContainer {
    /* padding: 0 50px; */
    margin-top: 210px;
  }
}

@media (max-width: 1024px) {
  .plantgrowthContainer {
    /* padding: 0 50px; */
    margin-top: 100px;
  }

  .boxPaddingFirstAndLastRow {
    padding: 10px 20px;
  }

  .boxPaddingCenterRow {
    padding: 10px 20px;
  }

  .mainHeadingContainer {
    width: 100%;
  }

  .mainHeading {
    font-size: 38px;
  }

  .leaf {
    top: 2px;
    right: 25vw;
  }

  .boxContent {
    font-size: 13px;
    width: 100%;
    padding: 0px 10px;
  }

  .boxHeading {
    font-size: 21px;
    text-align: start;
  }
}

@media (max-width: 769px) {
  .plantgrowthContainer {
    /* padding: 0 50px; */
    margin-top: 40px;
  }

  .boxPaddingFirstAndLastRow {
    padding: 10px 0px;
  }

  .boxPaddingCenterRow {
    padding: 10px 0px;
  }

  .mainHeading {
    font-size: 33px;
  }

  .leaf {
    top: -1px;
    right: 8vw;
  }

  .mainContent {
    font-size: 16px;
    line-height: 163.5%;
  }
  .boxContent {
    line-height: 19px;
    text-align: justify;
  }
}

.boxContainer {
  margin: 59px 0;
}

@media (max-width: 767px) {

  .plantgrowthContainer .mainHeadingContainer{
    margin-top: 40px;
  }

  .plantgrowthContainer {
    /* padding: 0 20px; */
    margin-top: -30px;
  }


  .boxPaddingFirstAndLastRow {
    padding: 0px;
  }

  .boxPaddingCenterRow {
    padding: 0px;
  }

  .mainHeadingContainer {
    width: 100%;
  }

  .mainHeading {
    font-size: 23px;
    line-height: 42px;
  }

  .leaf {
    top: -5px;
    right: 35vw;
  }

  .boxContainer {
    grid-template-columns: auto;
    margin: 0;
  }
  .mainContent {
    margin-top: 15px !important;
    margin: 0;
    text-align: justify;
  }

  .boxContent {
    width: 100%;
    font-size: 16px;
    padding: 0px;
    line-height: 163.5%;
  }

  .box {
    margin: 12px 0;
  }
}
@media (max-width: 376px) {
  .leaf {
    top: -5px;
    right: 11v;
  }
  .plantgrowthContainer {
    /* padding: 0 20px; */
    margin-top: -20px;
  }

  .mainHeadingContainer {
    width: 100%;
  }

  .mainHeading {
    font-size: 23px;
    line-height: 42px;
  }

  .leaf {
    top: -4px;
    right: 11vw;
  }

  .boxContainer {
    grid-template-columns: auto;
    margin: 0;
  }
  .mainContent {
    margin-top: 15px !important;
    margin: 0;
  }

  .boxContent {
    width: 100%;
    font-size: 16px;
    line-height: 163.5%;
  }

  .box {
    margin: 12px 0;
  }
}
@media (max-width: 322px) {
  .leaf {
    top: -5px;
    right: 11v;
  }
  .plantgrowthContainer {
    /* padding: 0 20px; */
    margin-top: -30px;
  }

  .mainHeadingContainer {
    width: 100%;
  }

  .mainHeading {
    font-size: 20px;
    line-height: 30px;
  }

  .leaf {
    top: -5px;
    right: 14vw;
  }

  .boxContainer {
    grid-template-columns: auto;
    margin: 0;
  }
  .mainContent {
    margin-top: 15px !important;
    margin: 0;
  }

  .boxContent {
    width: 100%;
    font-size: 16px;
    line-height: 163.5%;
    padding: 0px 0px;
  }

  .box {
    margin: 12px 0;
  }
}
