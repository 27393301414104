.image {
    position: relative;
    margin: 0;
    padding: 0;
}

.close_btn {
    position: absolute;
    right: 0px;
    z-index: 1;
    background-color: transparent;
    border: transparent;
    color: red;
}