.blogDetailPage {
    padding: 50px 100px 0 100px;
}

.blogHeading {
    font-family: var(--alice);
    font-weight: 00;
    font-size: 35px;
    line-height: 51px;
    color: var(--dark);
}

.blogDetail {
    font-family: var(--alice);
    font-weight: 400;
    font-size: 15px;
    line-height: 166.5%;
    color: var(--gray);
    margin: 15px auto;

}


.blogImage {
    text-align: center;
}

.blogImage img {
   width: 100%;
    object-fit: cover;
    margin-right: 17%;
}

.tagFields {
    display: flex;
    width: 100%;
    margin-top: 13px;
    
}

.blogDetailPage input {
    background: var(--light-3);
    margin-bottom: 30px;
    height: 40px;
    font-family: var(--alice);    font-style: var(--normal);
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    border: none;
    color: var(--lightGray);
    padding-left: 15px;
    
  
}

.productTag2 {
    margin-left: 10px;
}

.blogdetailPage input:focus {
    border: none;
    border-width: 0px;
}

.tagContainer {
    display: flex;
    margin-top: 30px;
}

.tag {
    font-family: var(--alice);
    font-weight: 00;
    font-size: 25px;
    line-height: 51px;
    color: var(--dark);
    margin-right: 30px;
    padding: 0;
}

.replyContainer {
    margin-top: -13px;
}

.leaveReply {
    width: 100% !important;
    margin-bottom: 16px;
}

.replyContainer p {
    color: #061A00;
    font-family: var(--alice);
    font-size: 15px;
    font-style: var(--normal);
    font-weight: 400;
    line-height: 156.523%;
    letter-spacing: 0.1px;
}

.submitBtnContainer {
    text-align: end;
}
/* Add this CSS class for the description container */
.descriptionContainer {
    margin-top: 15px; /* Adjust the margin to control spacing */
}
.blogDescription {
    font-family: var(--alice);    
    font-weight: 400;
    font-size: 15px;
    line-height: 166.5%;
    color: var(--gray);
    margin-top: 10%;
    text-align: justify;
}
/* Add this CSS class for the tag text */
.tagText {
    font-family: var(--alice);    
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: var(--lightGray);
    margin-top: 10px;
}



.contactField {
    padding: 0;
}

.formContainer {
    width: 100%;
    margin: 0;
}

.formContainer input {
    width: 100% !important;
}

.contactField textarea {
    background: var(--light-3);
    margin-bottom: 30px;
    font-family: var(--alice); 
    font-style: var(--normal);
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
    border: none;
    color: var(--lightGray);
    padding: 15px;
    width: 100%;
    height: 100% !important;
}

.fieldWidth {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0;
}

.fieldWidth input {
    width: 98% !important;
}

.fieldWidth .contactField:last-child {
    text-align: end;
}

.careerSubmitBtn {
    color: var(--white);
    font-family: var(--alice);    font-size: 15px;
    font-style: var(--normal);
    font-weight: 600;
    line-height: 150%;
    letter-spacing: -0.33px;
    border: none;
    background-color: var(--green);
    padding: 7px 15px;
}

.invalidDataError {
    color: red;
    margin-bottom: 10px;
    text-align: left;
}


/* YourStyles.module.css */

.commentContainer {
    position: relative;
    display: flex;
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .commentContainer::before {
    content: '';
    position: absolute;
    top: 50%;
    right: 100%; /* Position the arrow to the left of the container */
    border-width: 6px;
    border-style: solid;
    border-color: transparent transparent transparent #ddd; /* Arrow color */
    transform: translateY(-50%);
  }

  .container{
    display: flex;
    align-items: baseline;
  }


  
  .commentContent {
    flex-grow: 1;
    position: relative;
  }
  
  .nameSection {
    margin-bottom: 10px;
    font-size: small;
   
  }

  .dateSection{
    margin-left: 10px;
    font-size: x-small;
    color: grey;
   
    
  }
  
  .commentName {
    font-weight: bold;
    margin-left: 0px;
    display: flex;
  }
  
  .messageSection {
    display: flex;
    align-items: baseline;
  }
  
  .commentMessage {
    font-size: small;
  }

  .name{
    margin-left: 7px;
    font-size: 15px;
   
  }

  .message{
    margin-left: 7px;
    font-size: 13px;
  }
  


@media(max-width: 1025px) {
   
    .blogDetailPage {
        padding: 50px 50px 0 50px;
    }
    .productTags {
        margin-top: -6px;
        margin-left: -7px;
    }
    

    .blogHeading,
    .tag {
        font-size: 30px;
        margin-top: -7px;
    }

    .blogDetail,
    .replyContainer p {
        font-size: 15px;
    }

    .blogdetailPage input {
        width: 50%;
    }
    .blogDetailPage input {
    
    margin-left: 0px;
}

}

@media(max-width:768px) {
    .tagFields input {
        width: 49%;
    }

    .tagFields {
        width: 100%;
    }

    .blogdetailPage input {
        font-size: 13px;
    }

    .productTags {
        width: 100%;
        justify-content: space-between;
        display: flex;
        margin-left: 10px;
    margin-top: -10px;
    
    }

    .productTag2 {
        margin-left: 0px;
    }

    .tag {
        margin-right: 11px;
    }

    .replyContainer {
        margin-top: 36px;
    }
    

}

@media(max-width: 541px) {
    .blogDetailPage {
        padding: 20px 20px 0 20px;
    }

    .blogdetailPage .blogDetail {
        text-align: justify;
    }

    .blogHeading,
    .tag {
        font-size: 22px;
    }

    .blogdetailPage .formContainer .formInput {
        padding: 16px;
    }

    .tagFields {
        flex-direction: column;
    }

    .tagFields input {
        width: 100%;
        margin-bottom: 15px;
    }

    .contactField {
        margin-bottom: 15px;
    }

    .formInput,
    .fileInput {
        margin: 0px !important;
    }

    .tagContainer {
        display: block
    }

    .blogDetail,
    .replyContainer p {
        font-size: 13px;
        line-height: 149.523%;
    }

    .productTags {
        flex-direction: column;
        margin-top: -12px;
        margin-left: 0px;
    }

    .tagFields input {
        width: 100%;
    }

    .blogDetailPage input {
        height: 44px;
        font-size: 13px;
        padding-left: 13px;
        margin-bottom: 0px;
        margin-top: 5px;
    }

    .contactField textarea {
        font-size: 13px;
        padding: 13px;
        margin-bottom: 0;
    }

    .careerSubmitBtn {
        font-size: 13px !important;
    }

    .tag {
        line-height: 30px;
    }

    .replyContainer {
        margin-top: 21px;
    }

    .fieldWidth {
        flex-direction: column;
        padding: 0 !important;
    }

    .fieldWidth .contactField:last-child {
        text-align: start;
    }

    .fieldWidth .contactField:first-child {
        flex: none !important;
        max-width: auto !important;
    }

    .blogHeading {
        line-height: 36px;
    }

    .blogHeading,
    .tag {
        font-size: 20px;
    }

    .blogImage img {
        height: 250px;
    }
   
}