.cartContainer {
    padding: 0 110px;
    margin-top: 65px;
}

.mainContainer {
    position: relative;
    font-style: var(--normal);
}

.mainHeadingContainer {
    width: 50%;
    margin: auto;
    text-align: center;
}

input[type=number]::-webkit-inner-spin-button {
    opacity: 1
}

.buttonGroup {
    display: flex;
    gap: 1rem;
    align-items: center;
}



.buttonGroup button {
    border: none;
    height: 25px;
    width: 25px;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 25px;
    padding-bottom: 6px;
    color: white;
    background: #7fb401;
}

.buttonGroup button:focus {
    outline: none;
}

th {
    font-weight: 700 !important;
}

.leaf {
    position: absolute;
    top: 5px;
    right: 25px;
}

.productimge {
    border: 1px solid black;
    text-align: center;
    width: 65px;
}

.quantityfield {

    border: 1px solid #aaa;
    border-radius: 4px;
    font-size: 0.625rem;
    padding: 2px;
    width: 3.75rem;
}

.link {
    text-decoration: underline;
    color: var(--gray) !important;
}

.link:hover {
    color: var(--dark-1);
    text-decoration: underline;
}

.cartbutton {
    background: var(--green);
    color: var(--white) !important;
    font-size: 15px;
    font-weight: 600;
    line-height: 180.023%;
    padding: 8px 23px;
    border: none;
}

.proceedButton {
    text-align: right;
    /* margin-right: 11vw; */
}

.cartbutton {
    font-size: 15px;
}

.cartbutton {
    min-width: 118px;
    padding: 6px 23px;
}

.desktopBtn {
    display: none;
}

.mainHeading {
    position: relative;
    font-family: var(--alice);
    font-weight: 00;
    font-size: 45px;
    line-height: 51px;
    text-align: center;
    color: var(--dark);
    display: inline;

}

.mainContent {
    font-family: var(--alice);    font-weight: 400;
    font-size: 15px;
    line-height: 166.5%;
    text-align: center;
    color: var(--gray);
    margin: 15px auto;
    width: 71%;
}

.boxContainer {
    display: grid;
    grid-template-columns: auto auto auto;
    width: 100%;
    margin: 70px 0;
}

.box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    font-style: var(--normal);
}

.boxHeading {
    font-family: var(--alice);
    font-weight: 400;
    font-size: 25px;
    line-height: 29px;
    color: var(--dark);
}

.boxImg {
    width: 100%;
    height: 100%;
}

.boxContent {
    font-family: var(--alice);    font-weight: 400;
    font-size: 15px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.01em;
    color: var(--gray);
    width: 80%;
}
.productTable{
    min-width: 28rem;
}
.productTable2{
    min-width: 8rem;
}


/* Media Query */
@media(max-width: 1024px) {
    .proceedButton {
        text-align: right;
        margin-right: 3vw;
    }
/*
    .plantgrowthContainer {
        padding: 0 50px;
    } */

    .mainHeadingContainer {
        width: 68%;
    }

    .mainHeading {
        font-size: 38px;
    }

    .leaf {
        top: 2px;
        right: 18px;
    }

    .boxContent {
        width: 100%;
        padding: 0px 10px;
    }

    .boxHeading {
        font-size: 21px;
    }
    .Carttext{
        margin-left: 16rem !important;
    }
    .productTable{
        min-width: 8rem;
    }
    .productTable2{
        min-width: 0rem;
    }
}

@media(max-width:769px) {
    .cartContainer {
        padding: 0 50px;
        margin-top: 109px;
    }

    .mainHeading {
        font-size: 33px;
    }

    .leaf {
        top: 0px;
        right: 14px;
    }

    .mainContent {
        font-size: 16px;
        line-height: 163.5%;
    }
    .Carttext{
        margin-left: 10rem !important;
    }
    .productTable{
        min-width: 8rem;
    }
    .productTable2{
        min-width: 0rem;
    }
}

.boxContent {
    line-height: 19px;
}

.boxContainer {
    margin: 59px 0;
}


@media(max-width: 541px) {
    .cartContainer {
        padding: 0 20px;
        margin-top: 42px;
    }

    .mainHeadingContainer {
        width: 100%;
    }

    .mainHeading {
        font-size: 23px;
        line-height: 42px;
    }

    .leaf {
        top: -5px;
        right: 7px;
    }

    .boxContainer {
        grid-template-columns: auto;
        margin: 0;

    }

    .mainContent {
        margin-top: 15px !important;
        margin: 0;
    }

    .boxContent {
        width: 100%;
        font-size: 16px;
        line-height: 163.5%;
    }

    .box {
        margin: 12px 0;
    }

    .Carttext{
        margin-left: 5rem !important;
    }
    .productTable{
        min-width: 8rem;
    }
    .productTable2{
        min-width: 0rem;
    }
}
@media(max-width: 321px){
    .Carttext{
        margin-left: 2rem !important;
    }
    .productTable{
        min-width: 8rem;
    }
    .productTable2{
        min-width: 0rem;
    }
}
