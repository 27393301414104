.container {
  padding: 0px 110px;
  margin-top: 20px;
  /* width: 78%; */
}

.headingContainer {
  text-align: center;
}

.leaf {
  position: absolute;
  top: 267px;
  right: 597px;
}

.mainHeading {
  position: relative;
  font-family: var(--alice);
  font-weight: 400;
  font-size: 29px;
  line-height: 36px;
  display: inline;
}

.mainContent {
  font-family: var(--alice);
  font-style: var(--normal);
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: var(--gray);
  margin: 10px 0;
}

.productContainer {
  font-style: var(--normal);
  display: flex;
  /* background-color: #ccc !important; */
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
  /* gap: 24px; */
  /* justify-content: center; */
  /* width: 78%; */
  /* margin: auto; */
}

.productDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
  width: 100%;
  height: 100%;
}

.productBrand,
.productName {
  font-family: var(--alice);
  font-style: var(--normal);
  font-weight: 400;
  line-height: 25px;
  color: var(--white);
}

.homeProductSection {
  padding-top: 40px;
}

.productBrand {
  border-right: 2px solid var(--white);
  display: inline;
  padding-right: 20px;
}
/* .productDetails {
  position: relative;

} */

.productName {
  font-family: var(--alice);
  font-style: var(--normal);
  font-weight: bold;

  line-height: 54px;
  color: var(--white);
  text-align: center;
  width: 100%;
  /* letter-spacing: 2px; */
  /* font-size: 2rem; */
}

.productDescription {
  font-family: var(--alice);
  font-weight: 100;
  font-size: 17px;
  line-height: normal;
  color: var(--white);
  /* text-align: justify; */
}

.productDescription p{
  font-size: 18px;
  /* line-height: 22px; */
  /* height: 66px; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}

.productLink {
  font-family: var(--alice);
  /* font-weight: 600; */
  font-size: 20px;
  line-height: 131.5%;
  text-decoration: underline;
  color: var(--white);
  margin-top: -5px;
  color: white;
  margin-bottom: 20px;
  letter-spacing: 1px;
}

.productLink:hover {
  color: var(--white);
  text-decoration: underline;
}
.img {
  width: 277px;
  background-color: white;
  margin-top: 14px;
}

.content {
  font-family: var(--alice);
  font-style: var(--normal);
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: var(--gray);
  width: 100%;
}

.productImg {
  width: 100%;
  object-fit: cover;
}

.viewProducts {
  font-family: var(--alice);
  font-style: var(--normal);
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: var(--green);
}

.viewProducts:hover {
  text-decoration: underline;
  color: var(--green);
}

.p-carousel-indicators {
  padding: 47px;
}

.card {
  margin-top: 40px;
}

.customCarousel .p-carousel-prev,
.customCarousel .p-carousel-next {
  display: none !important;
  font-family: var(--alice);
}

/* Media Query */
@media (min-width: 1920px) {
  .productImg {
    width: 555px;
    /* margin-left: -50%; */
    object-fit: fill;
    position: relative;
    bottom: 1%;
  }
  .productDetails {
    position: relative;
    /* bottom: 12%; */
  }

  .productName {
    font-size: 40px;
  }
  .leaf {
    position: absolute;
    top: -3px;
    right: 55px;
  }

  /* .productDescription {
    font-size: 30px;
  } */

  .productContainer {
    /* height: 80%; */
  }
}

@media (min-width: 1441px) and (max-width: 1920px) {
  .leaf {
    position: absolute;
    top: -3px;
    right: 55px;
  }
}

@media (max-width: 1441px) {
  .productBrand {
    font-size: 30px;
  }

  .productDetails {
    position: relative;
    /* bottom: 15%; */
  }

  .leaf {
    position: absolute;
    top: -3px;
    right: 55px;
  }

  /* .productContainer {
    height: 78%;
  } */
}

@media (min-width: 1100px) and (max-width: 1300px) {
  .productDetails {
    position: relative;
    /* bottom: 30%; */
  }
  .leaf {
    position: absolute;
    top: -2px;
    right: 55px;
  }

  /* .productContainer {
    height: 70%;
  } */
}

@media (max-width: 1025px) {
  .container {
    padding: 0 50px;
  }
  .mainHeading {
    font-size: 38px;
  }
  .leaf {
    top: 2px;
    right: 79px;
  }
  .productContainer {
    /* width: 80%; */
    padding: 20px;
    position: relative;
    /* height: 100%; */
  }

  .productDetails {
    padding-right: 20px;
  }

  .productImg {
    /* height: 82%; */
    object-fit: contain;
    width: 100%;
  }

  /* .productContainer { */
  /* height: 95%; */
  /* width: 70%; */
  /* padding: 0% 6%; */
  /* } */

  /* .productImg { */
  /* height: 90%; */
  /* } */

  /* .productDetails { */
  /* right: 7%; */
  /* bottom: 2%; */
  /* } */

  /* .productName {
    margin-top: -6%;
    margin-left: 7%;
  } */
}

@media (max-width: 769px) {
  .mainHeading {
    font-size: 33px;
  }

  .leaf {
    top: -1px;
    right: 64px;
  }

  /* .productImg { */
  /* height: 80%; */
  /* } */

  .productBrand {
    font-size: 25px;
  }

  .productContainer {
    /* height: 90%; */
    /* width: 95%; */
    padding: 20px;
    flex-direction: column;
  }

  .productDetails {
    padding-top: 20px;
    position: relative;
    /* left: 2%; */
    padding-right: 0px;
    /* bottom: 8%; */
    height: 100%;
    justify-content: start;
    align-items: start;
  }

  /* .productName, */
  .productBrand {
    line-height: 29px;
  }
  /* .productName {
    margin-left: 3%;
  } */
}

@media (max-width: 541px) {
  .container {
    padding: 0 20px;
  }

  .mainHeading {
    font-size: 23px;
  }

  .leaf {
    top: -4px;
    right: 42px;
  }

  .card {
    margin-top: 23px;
  }

  .mainContent {
    margin: 4px 0;
  }
}

@media (max-width: 560px) {
  .productContainer {
    margin: 0px;
  }

  .productContainer {
    padding: 20px;
  }
}

@media (max-width: 376px) {
  /* .productContainer { */
  /* width: 95%; */
  /* height: 95%; */
  /* padding: 5% 0%; */
  /* } */

  .productBrand {
    font-size: 20px;
  }
  .productImg {
    width: 100%;
    /* height: 100%; */
    object-fit: cover;
    /* padding: 9%; */
    /* margin-top: -9%; */
  }
  .productDetails {
    position: relative;
    /* left: 6%; */
    /* top: 1px; */
  }
  .productDescription {
    width: 250px;
  }
  /* .productName {
    margin-top: -12%;
  } */
}
