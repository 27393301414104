.carousel {
  background-color: #2b481d;
  color: var(--white);
  width: 70%;
  height: 100%;
  margin: 2%;
  /* border: 3px solid var(--light-3); */
  box-shadow: 7px 7px 10px rgb(97, 96, 96);
  margin-left: 16%;
}

.carousel h2 {
  color: var(--mustard);
  font-size: 20px;
}
::before {
  color: green !important;
}
li.slick-active {
  height:0px !important;
}
.kissan {
  display: flex;
  justify-content: flex-end;
  margin-right: 5%;
  margin-bottom: 9%;
}
.form {
  width: 70%;
  height: 100%;
  margin-top: 5%;
  padding: 1.5vw;
  /* align-items: center; */
  margin-left: 16%;
  border: 3px solid var(--light-3);
  /* box-shadow: 0.1px 0.1px 0.1px 0.1px; */
  font-size: 15px !important;
}

.button {
  color: var(--white);
  font-family: var(--alice);
  font-size: 20px;
  font-style: var(--normal);
  line-height: 150%;
  letter-spacing: -0.33px;
  border: none;
  background-color: var(--green);
  padding: 7px 20px;
  border-radius: 5px;
}

.quote1 {
  position: relative;
  right: 14px;
  top: 14px;
}

.quote2 {
  position: relative;
  bottom: 5px;
}
.data input{
  height: 50px !important;
}
select#product_id {
  height: 50px;
}
textarea {
  height: 50px !important;
}
@media (max-width: 541px) {
  .carousel {
    width: 335px;
    margin-left: 45px;
  }

  .form {
    width: 80%;
    margin-left: 10%;
  }
}

@media (max-width: 376px) {
  .carousel {
    width: 335px;
    margin-left: 20px;
  }

  .form {
    width: 88%;
    margin-left: 6%;
  }

  .form-label {
    font-weight: 400;
  }
}

@media (max-width: 321px) {
  h1 {
    font-size: 2rem;
  }

  .data p {
    text-align: unset;
  }
  .kissan {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .carousel {
    width: 280px;
    margin-left: 20px;
  }

  .form {
    width: 88%;
    margin-left: 6%;
  }
}
