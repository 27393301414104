.thankYouPage {
  padding: 50px 100px 0 100px;
}

.vector {
  width: 117px;
  height: 117px;
  margin: auto;
  background: var(--green);
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.idBold {
  font-weight: bold;
}

.tick {
  font-size: 113px;
  color: var(--white);
}

.thankYouDescription {
  text-align: center;
  margin-bottom: 40px;
}

.thankYouDescription h1 {
  margin-top: 26px;
  font-family: var(--alice);
  font-style: var(--normal);
  font-weight: 400;
  font-size: 45px;
  line-height: 51px;
  color: var(--dark);
  position: relative;
  display: inline;
}

.leaf {
  position: absolute;
  top: 5px;
  right: 21px;
}

.thankYouDescription p {
  margin-top: 13px !important;
  width: 60%;
  margin: auto;
  font-family: var(--alice);
  font-style: var(--normal);
  font-weight: 400;
  font-size: 15px;
  line-height: 166.5%;
  text-align: center;
  color: var(--gray);
}

.orderDetails {
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
}

.orderDetails thead tr {
  border-bottom: 1px solid #323232;
  font-family: var(--alice);
  font-style: var(--normal);
  font-weight: 700;
  font-size: 25px;
  line-height: 50px;
  color: var(--gray);
}

.orderDetails tbody tr {
  font-family: var(--alice);
  font-style: var(--normal);
  font-weight: 500;
  font-size: 25px;
  line-height: 40px;
  color: var(--gray-1);
}

.productDetails {
  /* border-top: 1px solid var(--dark); */
  border-bottom: 1px solid var(--dark);
  border-collapse: collapse;
  width: 100%;
}

 

.productDetails thead {
  background: #7fb401;
}

.productDetails thead tr {
  font-family: var(--alice);
  font-style: var(--normal);
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: -0.022em;
  color: var(--white);
}

.productDetails thead tr th,
.productDetails tbody tr td {
  padding-top: 17px;
  padding-left: 61px;
  padding-bottom: 17px;
}

.productDetails thead tr th:last-child,
.productDetails tbody tr td:last-child {
  text-align: right;
  padding-right: 69px;
}

.productDetails td {
  padding: 10px;
  font-family: var(--alice);
  font-style: var(--normal);
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: -0.022em;
  color: var(--gray-1);
}

.productDetails tbody tr:last-child {
  border-top: 1px solid var(--dark);
}

.address {
  display: flex;
  padding: 40px 60px;
  justify-content: space-between;
  border-left: 1px solid var(--dark);
  border-right: 1px solid var(--dark);
  border-bottom: 1px solid var(--dark);
}

.billingAddress,
.shippingAddress {
  width: 30%;
}

.billingAddress h2,
.shippingAddress h2 {
  font-family: var(--alice);
  font-style: var(--normal);
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: -0.022em;
  color: (--gray-1);
}

.billingAddress p,
.shippingAddress p {
  width: 100%;
  font-family: var(--alice);
  font-style: var(--normal);
  font-weight: 500;
  font-size: 15px;
  line-height: 166.5%;
  color: var(--gray);
  margin-bottom: 47px;
}

.proceed {
  border: none;
  font-family: var(--alice);
  font-style: var(--normal);
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  letter-spacing: -0.022em;
  color: var(--white);
  background: var(--green);
  margin-top: 37px;
  padding-top: 4px;
  padding-bottom: 5px;
  width: 257px;
}

.proceedButton {
  text-align: center;
}

@media (max-width: 1024px) {

  .thankYouPage {
    padding: 50px 50px 0 50px;
  }

  .vector {
    width: 108px;
    height: 108px;
  }

  .tick {
    font-size: 93px;
  }

  .thankYouDescription p {
    width: 78%;
  }

  .proceed {
    font-size: 20px;
    padding: 9px 0;
  }

  .thankYouDescription h1 {
    font-size: 37px;
  }
}

@media(max-width: 768px) {
  .address {
    padding: 28px 35px;
  }

  .billingAddress,
  .shippingAddress {
    width: 40%;
  }

  .billingAddress p,
  .shippingAddress p {
    margin-bottom: 0;
  }

  .productDetails td {
    font-size: 16px;
  }

  .productDetails th,
  .billingAddress h2,
  .shippingAddress h2 {
    font-size: 18px;
  }

  .leaf {
    top: 3px;
    right: 15px;
  }
}

@media (max-width: 540px) {

  .thankYouPage {
    padding: 20px 20px 0 20px;
  }

  .thankYouDescription h1 {
    font-size: 27px;
    margin-top: 10px;
  }

  .thankYouDescription p {
    width: 100%;
  }

  .productDetails th,
  .productDetails td {
    padding: 11px !important;
  }

  .productDetails thead tr th:last-child,
  .productDetails tbody tr td:last-child {
    padding-right: 25px;
  }

  .address {
    display: block;
  }

  .productDetails thead tr th,
  .productDetails tbody tr td {
    padding-left: 25px;
  }

  .billingAddress,
  .shippingAddress {
    width: 100%;
  }

  .address {
    padding: 23px 11px;
  }

  .billingAddress {
    margin-bottom: 13px;
  }

  .proceed {
    width: 100%;
    font-size: 20px;
  }

  .vector {
    width: 70px;
    height: 70px;
  }
  .leaf{
    top: 0px;
    right: 7px;
  }
}