.container1 {
  width: 100%;
  border: 1px solid black;
  border-radius: 15px;
  margin-top:8vh;
}
.form {
  padding: 3vw;
}

.form-button {
  width: 49%;
  background-color: #7fb401;
  font-size: larger;
}
.distributorContainer input{
  border-radius: 2px;
}
.distributorContainer h3 {
  font-family:var(--alice);
}
.distributorContainer label, 
.distributorContainer p{
  font-size: 1.1rem;
}

 .submitBtn {
  border-radius: 2px;
  font-size: 0.9rem;
  background-color: #7fb401;
} 