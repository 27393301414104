.orderContainer {
    margin-left: 30px;
    padding: 22px;
    color: var(--gray);
    font-family: var(--alice);    font-style: var(--normal);
}

.ViewOrderDetails {
    padding: 0px 110px;
}

.trackOrderButton{
    width: 20% !important;
}

/* .ViewOrderDetails .orderDetail {
   
    padding: 0 110px;
} */

.ViewOrderDetails .orderDetail table {
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%);

}

.returnModal{
    width: 50%;
}

.ViewOrderDetails .orderDetail th {
    margin: 10px 0 !important;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    white-space: nowrap;
}

.ViewOrderDetails .orderDetail td {
    color: var(--gray-1);
    white-space: nowrap;
}

.ViewOrderDetails .tableHeading {
    color: var(--gray) !important;
    font-style: var(--normal);
    font-family: var(--alice);    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: normal;
    margin: 25px 0px;
}

.ViewOrderDetails .product {
    margin: auto !important;
    width: 88% !important;
}

.ViewOrderDetails .product td{
   white-space: nowrap;
}

.ViewOrderDetails .returnOrder {
    display: flex;
    justify-content: end;
}

.ViewOrderDetails .headingWidth {
    width: 30%;
}

.ViewOrderDetails .headingWidth,
.ViewOrderDetails .headingQuantity {
    white-space: nowrap;
}

.ViewOrderDetails .headingQuantity {
    width: 20%;
}

.orderHistory {
    color: var(--gray);
    font-style: var(--normal);
    font-family: var(--alice);    font-size: 25px;
    font-weight: 600;
    line-height: normal;
    margin: 25px 0px;
}

.history {
    margin: 15px 0;
    font-size: 15px;
    font-weight: 400;
    line-height: 166.5%;
}

.dropdown {
    height: 45px;

}

.textareaformControl {

    height: 30% !important;
    min-width: 100%;
    margin-bottom: 20px;

}

.retunorder {
    padding: 0 24px;
    width: 100%;
}

.retunorderh4 {
    padding: 12px 0px !important;
    font-size: 18px;
}

.card {
    margin-top: 30px;
    background: #F5F6F8;
    padding: 24px 20px;
}

.status {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.statusDot {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: var(--green-5);
    margin: 0 10px;
}

.orderStatus {
    color: #757678;
    font-size: 20px;
    line-height: 156.523%;
    letter-spacing: 0.09px;
    margin: 0;
}

.orderId {
    color: black !important;
}

.product {
    display: flex;
    justify-content: space-between;
    padding: 20px 16px;
    width: 80%;
    background: var(--white);
    box-shadow: 3px 4px 4px 0px rgba(0, 0, 0, 0.15);
}

.productTracking {
    width: 40%;
}

.image {
    border: 2px solid var(--lightGray);
    width: 40%;
    text-align: center;
}

.image img {
    height: 200px;
    padding: 10px;
}

.productDetails {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: var(--alice);    font-style: var(--normal);
    font-weight: 600;
}

.productName {
    color: var(--gray);
    font-size: 25px;
    line-height: normal;
}

.varient {
    color: var(--white);
    font-size: 15px;
    line-height: 180.023%;
    background-color: var(--green-5);
    width: 98px;
    text-align: center;
    padding: 10px;
}

.price {
    display: flex;
    align-items: center;
    color: var(--gray);
    font-size: 25px;
    line-height: normal;
}

.trackOrder {
    margin-bottom: 1rem;
    width: 100%;
    background-color: var(--green-5);
    color: var(--white);
    border: none;
    padding: 10px;
}
.disabled {
    background-color: grey;
    color: #ccc; /* You can adjust the text color as well */
    cursor: not-allowed;
  }

.innerCard {
    display: flex;
}

.productTracking {
    font-family: var(--alice);    font-size: 20px;
    font-style: var(--normal);
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.04px;
    margin: 0 50px;
    display: flex;
    flex-direction: column;
    justify-content:center;
    text-align: center;
}

.viewOrderDetail {
    color: var(--gray);
}

.viewOrderDetail:hover {
    color: var(--gray);
}

.invoice {
    cursor: pointer;
}

.deliveredDot {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: var(--lightGray);
    margin: 0 10px;
}

@media(max-width: 1024px) {
    .ViewOrderDetails {
        padding: 0px 50px;
    }

    .orderContainer {
        margin-left: 0px;
    }

    .productDetails {
        width: 50%;
    }

    .productName,
    .price {
        font-size: 20px;
    }

    .retunorderh4 {
        padding: 12px 0px !important;
        font-size: 16px;
    }
    

    /* .varient {
        width: 50%;
    } */

    .image img {
        height: 180px;
    }
    .trackOrderButton{
        width: 36% !important;
        font-size: 14px;
    }
    .returnModal{
        width: 80%;
    }
}

@media(max-width: 768px) {
    .trackOrder {
        padding: 6px;
    }
    .status {
        display: block;
    }

    .productTracking {
        margin: 0 27px;
    }

    .product {
        width: 70%;
    }

    .varient {
        /* width: 55%; */
        font-size: 13px;
        padding: 6px;
    }

    .productTracking {
        font-size: 18px;
    }

    .orderStatus {
        font-size: 17px;
    }

    .image img {
        height: 158px;
    }

    .ViewOrderDetails .product {
        width: 100% !important;
    }

    .ViewOrderDetails .orderDetail {
        width: 95%;
    }

    .ViewOrderDetails .tableHeading {
        font-size: 14px !important;
    }
}

@media(max-width: 541px) {
    .ViewOrderDetails {
        padding: 0px 20px;
    }

    .returnModal{
        width: 100%;
    }

    .ViewOrderDetails .orderHistory {
        font-size: 22px;
        margin: 18px 0px;
    }

    .ViewOrderDetails .tableHeading {
        font-size: 12px !important;
    }

    .card {
        padding: 8px;
        margin-top: 18px;
    }

    .status {
        margin-bottom: 10px;
    }

    .productTracking {
        font-size: 12px;
    }

    .image img {
        height: 146px;
    }

    .productName,
    .price {
        font-size: 16px;
    }

    .varient {
        width: 70px;
        font-size: 10px;
    }

    .product {
        width: 100%;
    }

    .innerCard {
        flex-direction: column;
    }

    .productTracking {
        width: 100%;
        margin: 0px;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        margin-top: 12px;
    }

    .trackOrder {
        width: auto;
    }

    .spacing {
        margin-left: 12px;
    }

    .invoice {
        margin-bottom: 0;
    }

    .orderStatus {
        font-size: 15px;
    }
}

@media(max-width:425px) {
    .spacing {
        margin-left: 10px;
    }

    .productTracking {
        font-size: 10px;
    }

    .product {
        padding: 13px;
    }
}