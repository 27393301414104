.mainContainer {
  /* padding: 40px 110px; */
  position: relative;
  /* margin-top: 130px; */
  margin-bottom: 55px;
  padding-top: 30px;
}

.blogMargin {
  margin-top: 0px;
}

.background {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  width: 100%;
}

.headingDiv {
  text-align: center;
}

.blog_background_div {
  position: absolute;
  height: 100%;
  top: 0;
  width: 100%;
  background: var(--light);
  left: 0;
}

.mainHeading {
  font-family: var(--alice);
  font-style: var(--normal);
  font-weight: 400;
  font-size: 29px;
  /* line-height: 51px; */
  position: relative;
  display: inline;
}

.leaf {
  position: absolute;
  top: -3px;
  right: 20px;
}

.mainContent {
  font-family: var(--alice);
  font-style: var(--normal);
  font-weight: 400;
  font-size: 1.1rem;
  /* line-height: 166.5%; */
  text-align: justify;
  color: var(--gray);
  text-align: center;
  margin: 20px auto;
  position: relative;
}

.blogContainer {
  width: 100%;
  height: 239px;
  display: flex;
  margin: 26px auto;
  position: relative;
  box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.25);
}

.blogImg {
  width: 50%;
  height: 100%;
  position: relative;
}

.blogImg img {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
}

.blogContent {
  width: 73%;
  background: var(--white);
  padding: 1vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.writerImage {
  width: 4%;
}

.blogHeading {
  font-family: var(--alice);
  font-style: var(--normal);
  font-weight: 400;
  font-size: 22px;
  /* line-height: 29px; */
  color: var(--dark);
}

.postContent {
  font-family: var(--alice);
  font-style: var(--normal);
  font-weight: 400;
  font-size: 13px;
  /* line-height: 20px; */
  letter-spacing: 0.01em;
  color: var(--gray);
  margin: 14px auto;
  min-height: 62px;
}

.readMoreBtn {
  border: 1px solid var(--green);
  border-radius: 30.5px;
  font-family: "Alice";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  /* line-height: 135.52%; */
  letter-spacing: 0.01em;
  color: white;
  padding: 10px 27px;
  transition: all 0.3s ease-in-out;
  background-color: var(--green);
}

.readMoreBtn:hover {
  background-color: var(--green);
  color: var(--white);
}

.writer {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.writerName {
  margin: 0 10px;
  font-size: 16px;
  font-weight: 400;
}

.viewButtonContainer {
  text-align: center;
}

.viewMore {
  padding: 10px 20px !important;
}

.arrowBtn {
  margin-left: 10px;
  font-size: 18px;
}

.readMoreBtn:hover .arrowBtn {
  color: var(--white);
}

.date {
  font-family: var(--alice);
  font-style: var(--normal);
  font-weight: 400;
  width: 13%;
  /* height: 31%; */
  position: absolute;
  text-align: center;
  top: 0;
  left: 65px;
  background-color: var(--green);
  padding: 10px 0;
}

.postDate {
  font-size: 30px;
  line-height: 36px;
  margin: 0;
  color: var(--white);
}

.postMonth {
  font-size: 12px;
  line-height: 15px;
  margin: 0;
  color: var(--white);
}

/* Media Query */
@media (min-width: 1500px) {
  .blogContent {
    width: 95%;
  }
}

@media (max-width: 1441px) {
  .blogContainer {
    height: 253px;
  }
}

@media (max-width: 1024px) {
  .blogImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  /*
  .mainContainer {
    padding: 40px 50px;
  } */

  .mainHeading {
    font-size: 38px;
  }

  /* .blogContainer {
        height: 223px;
    } */

  .blogHeading {
    font-size: 18px;
  }

  .mainContent {
    width: 100%;
  }

  .blogContent {
    padding: 10px;
  }

  .postContent {
    padding: 0;
  }

  .date {
    left: 28px;
    width: 17%;
  }

  .leaf {
    top: 1px;
    right: 30px;
  }
}

@media (max-width: 769px) {
  .background {
    height: 205px;
  }

  .mainHeading {
    font-size: 33px;
  }

  .leaf {
    right: 24px;
    top: -1px;
  }

  /* .blogContainer {
        height: 205px;
    } */

  .readMoreBtn {
    padding: 7px 20px;
  }

  .date {
    width: 22%;
    /* height: 31%; */
  }

  .postContent {
    /* margin: 2px auto; */
    margin-top: 3px;
    font-size: small;
  }

  /* .blogs { */
  /* margin: 41px auto; */
  /* } */

  .writer {
    margin-top: 14px;
  }

  /* .blogContent{

    width: auto;
    } */
  .blogImg img {
    /* height: 85%; */
    /* margin-top: 18px; */
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (max-width: 541px) {
  .mainContainer {
    padding: 20px;
  }

  .background {
    height: 76px;
  }

  .mainContent {
    font-size: 17px;
  }

  .mainHeading {
    font-size: 23px;
  }

  .mainContent {
    margin: 12px auto;
    /* line-height: 163.5%; */
  }

  .blogContainer {
    flex-direction: column;
    height: 100%;
  }

  .responsiveBlogContainer {
    flex-direction: column-reverse !important;
  }

  .blogImg {
    width: 100%;
  }

  .blogContent {
    width: 100%;
  }

  .date {
    width: 16%;
    /* height: 39%; */
  }

  .leaf {
    top: -6px;
    right: 9px;
  }

  .viewMore {
    padding: 8px 14px !important;
  }

  .postDate {
    font-size: 20px;
    line-height: 29px;
  }

  .writerImage {
    width: 29px;
    height: 34px;
  }
}

@media (max-width: 320px) {
  .date {
    width: 21%;
    /* height: 45%; */
  }
}
