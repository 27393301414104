.addresses {
    margin: 20px 0;
  }
  
  .addresses h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 156.52%;
    letter-spacing: 0.005em;
    margin-bottom: 23px;
  }
  
  .address_section {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(32%, 1fr));
    grid-gap: 20px;
  }
  
  .address_section .shipping-card {
    width: 100%;
    margin-left: auto;
    height: 240px;
    border: 1px solid #d9d9d9;
    padding: 12px;
  }
  
  .card_1{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .address_section .new_address_icon {
    color: #575656;
    font-size: 15px;
    font-weight: 600;
    line-height: 156.523%;
    letter-spacing: 0.075px;
  }
  
  .add_address {
    text-align: center;
  }
  
  .address_section .deliver_div {
    text-align: end;
  }
  
  .address_section .deliver_btn {
    background: #7FB401;
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    padding: 9px 11px;
  }
  
  .card_2 h4 {
    font-size: 20px;
    font-family: Inter;
    font-weight: 600;
    line-height: 156.523%;
    letter-spacing: 0.1px;
  }
  
  .address_section .phone_number {
    color: #575656;
    font-size: 15px;
    line-height: 156.523%;
    letter-spacing: 0.075px;
  }
  
  .address_section .edit_button {
    color: #575656;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.075px;
    border: none;
    background-color: white;
  }
  
  .address_section .deliver_btn1 {
    color: #7FB401 !important;
    border: 1px solid #7FB401 !important;
    background: none !important;
  }
@media (max-width: 426px){
    
    .address_section {
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr)) !important;
        grid-gap: 20px;
    }

    .address_section .shipping-card {
        height: 209px;
    }

    .address_section .deliver_btn {
        font-size: 13px;
    }

    .card_2 h4 {
        font-size: 18px;
    }

    .address_section .order_address {
        margin: 6px 0;
    }

    .addresses h2 {
        margin-bottom: 18px;
    }
}

@media(max-width:321px){
    .address_section .order_address {
        font-size: 14px;
    } 
    
}